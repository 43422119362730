.layout
  &--fullwidth
    width: 100%

    .layout__grid
      padding: 50px 40px
      width: 100%
      max-width: 1250px
      margin-left: auto
      margin-right: auto

  &--bgcolor
    background-color: $lightBg

  &__grid
    display: flex
    margin: 87px -15px
    align-content: center

  &__col
    position: relative
    flex: 0 1 100%
    min-width: 1px
    min-height: 0
    display: flex
    flex-direction: column
    padding: 15px
    img
      width: fit-content
      margin: 0 auto

  &__box
    padding: 0px 50px 0px 100px
    margin: auto 0

  &--slanted
    transform: rotate(-7deg)
    width: 105%
    margin-left: -2.5%
    clip-path: polygon(0 0%, 100% 0%, 100% 100%, 0% 100%)
  &--slanted
    .responsive
      transform: rotate(7deg)
      margin-bottom: 50px
      &__row
        padding: 70px 0

  &--slanted .layout__grid
    transform: rotate(7deg)
    padding: 50px 40px 80px

+r($tablet)
  .layout--slanted
    transform: rotate(0)
    width: 100%
    margin-left: 0%
    .responsive
      transform: rotate(0)
      margin-bottom: 50px
      &__row
        padding: 0px 0 30px

    .layout__grid
