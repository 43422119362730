.hero
  position: relative
  z-index: 2
  background: #F6F7FB
  margin-top: 90px
  overflow: hidden
  &__bg
    position: absolute
    top: 0
    bottom: 0
    right: 0
    width: auto
    text-align: right
    max-width: 100%
    font-size: 0
    z-index: -1

  > .container
    position: relative
    z-index: 2
    display: flex
    flex-direction: column
    min-height: 410px

  &__wrapper
    width: 62%
    padding: 120px 0 80px
    // margin-top: auto
    margin-bottom: auto
    // display: flex
    // flex-direction: column
    h2
      margin: 37px 0 26px
      letter-spacing: -1px
      line-height: 38px
    .t-paragraph
      margin: 0

    .btn--huge
      margin-top: 54px
      width: 121px

  &__nav
    position: absolute
    bottom: 0
    left: 0
    right: 0
    z-index: 3
    &-wrapper
      display: flex
      margin-left: -20px
      margin-right: -20px
    &-el
      // flex: 0 1 245px
      // max-width: 245px
      flex: 0 1 200px
      max-width: 200px
      margin-left: 20px
      margin-right: 20px
      font-size: 16px
      color: $fontColorGray
      font-weight: 400
      border-top-left-radius: 3px
      border-top-right-radius: 3px
      text-align: center
      padding: 23px 10px 18px
      cursor: pointer
      transition-property: background, box-shadow
      transition-duration: .25s
      transition-timing-function: $ease
      &:hover
        // box-shadow: 0px 0px 30px rgba(black, .05)
        background: rgba(white, .5)
      &.is-active
        font-weight: 500
        background: white
    &-mobile
      display: none
      max-width: 400px
      margin-left: auto
      margin-right: auto

  &__info
    min-width: 540px
    font-size: 13px
    color: $fontColorGray
    line-height: 1.42
    padding: 20px 35px
    background: white
    margin-top: 30px
    margin-bottom: -33px

  &__footer
    text-align: center
    background-color: $cabinBlue
    padding: 21px 40px 25px
    color: #FFFFFF
    font-size: 12px
    line-height: 18px
    position: absolute
    left: 0
    right: 0
    bottom: 0
    z-index: 2

.hero__with-footer
  > .container
    padding-bottom: 64px

+r(1350)
  #services-annual
    .hero__bg
      right: -50px
  #services-monthly
    .hero__bg
      right: -80px

+r($desktop)
  .hero
    > .container
      min-height: 340px
    &__wrapper
      width: 65%
      // margin: auto
    &__bg
      img
        position: absolute
        top: 50%
        transform: translate(15%, -50%)
        right: 0
        // max-width: 100%
        height: 100%
    &__nav
      &-wrapper
        margin-left: -10px
        margin-right: -10px
      &-el
        flex: 0 1 180px
        max-width: 180px
        font-size: 14px
        margin-left: 10px
        margin-right: 10px

  #services-monthly, #services-annual
    .hero__bg
      right: -250px

+r($tablet)
  .hero
    background: transparent
    overflow: visible
    > .container
      min-height: auto
    &__bg
      position: relative
      z-index: 1
      background: #F6F7FB
      height: 300px
      overflow: hidden
      // padding-left: 20%
      img
        position: absolute
        top: 50%
        transform: translateY(-50%)
        right: 0
        // max-width: 100%
        height: 100%
    &__footer
      position: relative
    &__wrapper
      width: 100%
      padding: 50px 0

    &__info
      margin-top: 20px
      margin-bottom: 0
      min-width: auto
      background: #F6F7FB

    &__nav
      position: static
      &-wrapper
        display: none
      &-mobile
        display: block
  .hero__with-footer
    > .container
      padding-bottom: 0px

  #services-monthly, #services-annual
    .hero__bg
      right: 0px
+r($tablet)
  .hero
    &__wrapper
      h2
        font-size: 22px
        margin: 21px 0 17px
        line-height: 32px
      .btn--huge
        margin-top: 42px
+r($mobile)
  .hero
    &__bg
      height: 220px
      img
        height: 100%

// +r(1600)
//   #services-monthly, #services-annual
//     .hero__wrapper
//       width: 50%
// +r(1350)
//   #services-monthly, #services-annual
//     .hero__wrapper
//       width: 45%

// +r(1110)
//   #services-monthly, #services-annual
//     .hero__wrapper
//       width: 50%
// +r(992)
//   #services-monthly, #services-annual
//     .hero__wrapper
//       width: 45%
// +r($tablet)
//   #services-monthly, #services-annual
//     .hero__wrapper
//       width: 100%
