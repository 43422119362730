.hamburger
  padding: 8px 10px 3px 10px
  display: inline-block
  cursor: pointer
  transition-property: opacity, filter
  transition-duration: 0.15s
  transition-timing-function: linear
  font: inherit
  color: inherit
  text-transform: none
  background-color: transparent
  border: 0
  margin-right: -10px
  overflow: visible
  &:hover
    opacity: 0.7
  *
    outline: none

.hamburger-box
  width: 18px
  height: 15px
  display: inline-block
  position: relative

.hamburger-inner
  display: block
  top: 50%
  margin-top: -2px
  width: 18px
  height: 3px
  border-radius: 1px
  background-color: $colorBlue
  position: absolute
  transition-property: transform
  transition-duration: 0.15s
  transition-timing-function: ease
  &::before, &::after
    width: 18px
    height: 3px
    border-radius: 1px
    background-color: $colorBlue
    position: absolute
    transition-property: transform
    transition-duration: 0.15s
    transition-timing-function: ease
  &::before, &::after
    content: ""
    display: block
  &::before
    top: -8px
  &::after
    bottom: -8px


.hamburger--squeeze
  .hamburger-inner
    transition-duration: 0.1s
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19)
    &::before
      transition: top 0.1s 0.14s ease, opacity 0.1s ease
    &::after
      transition: bottom 0.1s 0.14s ease, transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19)
  &.is-active .hamburger-inner
    transform: rotate(45deg)
    transition-delay: 0.14s
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1)
    &::before
      top: 0
      opacity: 0
      transition: top 0.1s ease, opacity 0.1s 0.14s ease
    &::after
      bottom: 0
      transform: rotate(-90deg)
      transition: bottom 0.1s ease, transform 0.1s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1)
