@keyframes tooltip
  0%
    opacity: 0
    top: -20px
  100%
    opacity: 1

.home
  h2
    font-size: 22px
  &__tooltip_in
    animation-name: tooltip
    animation-duration: 0.5s
    animation-iteration-count: 1
    animation-timing-function: ease-out
  &__tooltip
    opacity: 0
    position: absolute
    z-index: 2
    top: 0px
    left: calc(52% - 65px)
    background: white
    min-width: 130px
    box-shadow: 0 0 20px 0 rgba(0,0,0,0.08)
    border-radius: 3px
    font-size: 13px
    color: $fontColorGray
    pointer-events: none
    // cursor: pointer
    opacity: 1
    transition: opacity .3s $ease
    &--isaac
      left: 27%

    &-wrapper
      padding: 20px 10px 17px 18px
      position: relative
      &::after
        display: inline-block
        content: ' '
        position: absolute
        bottom: -9px
        left: 50%
        transform: translateX(-50%)
        +arr(20,10,#fff,b)
      span
        display: block
        margin-top: 4px
        font-size: 18px
        font-weight: 500
        color: $colorBlue
.home-hero
  position: relative
  > .container
    position: relative
    z-index: 2
  &__wrapper
    padding-top: 160px
    padding-bottom: 120px
    // show logos on first screen
    height: calc(100vh - 120px)
    max-height: calc(800px - 120px)
    min-height: 650px
    display: flex
    flex-direction: column
    justify-content: center
    max-width: 550px
    .btn
      &--huge
        margin-top: 29px
        width: 121px
  h1
    font-size: 34px
    margin-bottom: .5em
    color: $cabinBlue
  .t-paragraph
    max-width: 420px
    strong
      color: $cabinBlue
  &__form
    margin-top: 80px

  &__bg
    position: absolute
    z-index: 1
    right: 0
    width: 50%
    top: 70px
    bottom: 0
    font-size: 0
    display: flex
    flex-direction: column
    align-items: flex-end
    justify-content: flex-end
    pointer-events: none
    user-select: none
    text-align: right
    img, .home-hero__lottie
      width: 850px
      height: 602px
      margin-bottom: -2px
      transform: translateX(50px)


+rmin(1440)
  .home-hero
    &__bg
      // align-items: flex-start
      padding-left: 30px
      left: 50%
      width: calc(850px + 30px)
      img, .home-hero__lottie
        transform: translateX(-110px)
      &::after
        display: inline-block
        content: ' '
        position: absolute
        bottom: 0px
        top: 0
        right: 0
        width: 100px
        background: linear-gradient(-90deg, #FFFFFF 0%, rgba(255,255,255,0.01) 100%)
        // adjust to new layout
        transform: translateX(-110px)

+r(1380)
  .home-hero
    &__bg
      align-items: flex-start
      img, .home-hero__lottie
        transform: translateX(-110px)

// recently was on mobile
// +r($mobile)
+r(1200)
  .home-hero
    &__wrapper
      padding-top: 140px
      padding-bottom: 100px
      height: calc(100vh - 120px)
      max-height: calc(740px - 120px)
      min-height: 590px
      max-width: 500px
    &__bg
      align-items: flex-start
      img, .home-hero__lottie
        max-width: 750px
        max-height: 531px
        transform: translateX(-90px)

+r($desktop)
  .home-hero
    &__bg
      img, .home-hero__lottie
        transform: translateX(-0px)

+r($tablet)
  .home-hero
    display: flex
    flex-direction: column-reverse
    &__bg
      // align-items: flex-end
      align-items: flex-start
      position: relative
      top: 0
      right: 0
      bottom: 0
      left: 0
      transform: none
      padding-top: 70px
      width: 109%
      // keep flex for right align
      // display: block
      overflow: hidden
      margin-left: -9%
      img, .home-hero__lottie
        max-width: 100%
        max-height: 100%
        // max-width: 200%
        // max-height: 200%
        // 850px originally
        // max-width: 700px
        width: 100%
        height: 100%
        // width: 109%
        // height: 109%
        transform: translateX(0%)
      &::after
        display: inline-block
        content: ' '
        position: absolute
        bottom: 0px
        left: 0
        right: 0
        height: 100px
        background: linear-gradient(0deg, #FFFFFF 0%, rgba(255,255,255,0.01) 100%)
    &__lottie
      font-size: 0
    &__form
      margin-top: 45px
    &__wrapper
      min-height: auto
      height: auto !important
      max-height: 100% !important
      padding-bottom: 75px
      padding-top: 20px
      max-width: 100%
    .t-paragraph
      max-width: 100%
      
.divider
  height: 403px
  width: 26px
  cursor: grab

.divider:active
  cursor: grabbing


+r($tablet)
  .btn--view-plan
    width: 100%

+r($tablet)
  .home-hero
    &__bg
      width: 108%
      margin-left: -8%


+r($mobile-s)
  .home-hero
    &__bg
      width: 134%
      // width: 147%
      margin-left: 0
      img, .home-hero__lottie
        transform: translateX(-8.2%)
    &__wrapper
      padding-top: 20px

.home-logos
  position: relative
  z-index: 2
  background: #FAFBFF
  padding: 15px 0
  &__wrapper
    display: flex
    align-items: center
    justify-content: center
  &__name
    flex: 0 0 auto
    font-size: 15px
    color: $fontColorGray
    margin-right: 35px

  &__list
    display: flex
    // align-items: center
    > div
      display: flex !important
      align-items: center
  &__el
    position: relative
    flex: 1 0 auto
    padding: 15px 26px
    font-size: 0
    opacity: 0.4

+r($desktop)
  .home-logos
    &__wrapper
      flex-wrap: wrap
      flex-direction: column
    &__name
      margin-right: 0
      margin-top: 10px
      margin-bottom: 20px
    &__list
      flex-wrap: wrap
      justify-content: center

+r($mobile)
  .home-logos
    &__list
      // margin-left: -20px
      // margin-right: -20px
      > div
        flex: 0 1 50%
        max-width: 50%
    &__el
      display: flex
      justify-content: center
      padding: 20px 15px
    &__name
      font-size: 13px


.home-promo
  &__wrapper
    display: flex
    align-items: center
    margin-left: -15px
    margin-right: -15px
  &__left
    flex: 0 1 50%
    max-width: 50%
    min-width: 1px
    min-height: 0
    padding: 0 15px
    transform: translate(-60px, -20px)
  &__right
    flex: 0 1 50%
    max-width: 50%
    min-width: 1px
    min-height: 0
    padding: 35px 15px 0


/* Home benefits */
.home-benefits
  margin-top: 120px
  &__tabs
    margin-top: 55px

  &__content
    position: relative
    overflow: hidden
    display: flex
    padding: 90px 30px 70px
    margin-left: -15px
    margin-right: -15px
    &::after
      background: url('../images/circles-bg.png') no-repeat right bottom
      background-size: auto 100%
      display: inline-block
      content: ' '
      position: absolute
      z-index: 1
      right: 15px
      bottom: 0
      top: 0
      left: 15px
       // width: 705px
    &-left
      position: relative
      z-index: 2
      flex: 0 1 45%
      max-width: 45%
      min-width: 1px
      min-height: 0
      padding: 0 15px
      display: flex
      flex-direction: column
    &-right
      position: relative
      z-index: 2
      flex: 0 1 55%
      max-width: 55%
      min-width: 1px
      min-height: 0
      padding: 0 15px
      h3
        margin-top: -.2em
         // font-size: 24px
      .testimonial
        margin: 35px 0 40px
      .btn
        +text-overflow
        br
          display: none
    h3
      font-size: 24px
      span
        color: $colorGreen
      &.for-mobile
        display: none

    .t-paragraph
      line-height: 1.53
      font-size: 15px
      // max-width: 440px
      &.for-mobile
        display: none

    .ico-question-circle
      margin-left: 6px
      color: $colorBlue
      font-size: 17px
      transform: translateY(3px)

  &__list
    list-style: none
    margin: 0 auto
    padding: 0
    width: auto

    li
      display: flex
      align-items: center
      margin-bottom: 15px
      // transform: translateZ(0)
      &.is-disabled
        opacity: .15
        .home-benefits__list-icon
          background: $fontColorGray
    &-icon
      position: relative
      flex: 0 0 auto
      display: flex
      align-items: center
      justify-content: center
      background: $colorGreen
      border-radius: 50%
      width: 26px
      height: 26px
      margin-right: 20px
      color: white
      /* aos */
      overflow: visible
      z-index: 2
      will-change: transform
      backface-visibility: hidden
      opacity: 0
      transform: scale(0.5)
      .ico
        width: 15px
        height: 15px
        font-size: 11px
        transform: translate(.5px,0)
    span
      flex: 0 1 auto
      display: inline-block
      font-size: 14px
      font-weight: 300
      opacity: 0
      transform: translate3d(-10px, 0, 0)

    &-icon, span
      transition-property: opacity,transform
      transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715)
      transition-duration: 350ms

+r($tablet)
  .home-benefits
    margin-top: 90px
    &__content
      flex-direction: column
      padding: 30px
      &::after
        display: none
      &-left
        flex: 0 0 auto
        max-width: 100%
      &-right
        flex: 0 0 auto
        max-width: 100%
        .testimonial
          margin-bottom: 20px

      h3
        display: none
        &.for-mobile
          display: block
      .t-paragraph
        display: none
        &.for-mobile
          display: block

    &__tabs
      margin-top: 45px
    &__slider
      /* contatiner */
      position: relative
      overflow: hidden
      margin-left: -40px
      margin-right: -40px
      list-style: none
      padding: 0
      z-index: 1
      &-next, &-prev
        position: absolute
        z-index: 2
        top: 18px
        background-repeat: no-repeat
        background-size: contain
        size: 12px 16px
        font-size: 0
        cursor: pointer
        transition: .25s $ease
        &:hover:not(.swiper-button-disabled)
          opacity: .8
        &.swiper-button-disabled
          filter: grayscale(1)
          opacity: .2
          cursor: not-allowed

      &-next
        right: 40px
        background-image: url('../images/icons/right-arrow.svg')

      &-prev
        left: 40px
        background-image: url('../images/icons/back-arrow.svg')


    &__slide
      padding-left: 40px
      padding-right: 40px
      padding-bottom: 30px

      &-name
        text-align: center
        font-size: 14px
        font-weight: 500
        margin-bottom: 30px
        padding: 20px 40px
        background: white
        border-radius: 3px
        box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.05)

    &__list
      margin-left: 0
      margin-top: 25px
      li
        &.is-disabled
          display: none

+r($mobile)
  .home-benefits
    // margin-top: 90px
    &__content
      padding: 15px
      &-right
        .btn
          br
            display: block
      h3
        font-size: 20px
    &__slider
      margin-left: -20px
      margin-right: -20px
    &__slide
      padding-left: 20px
      padding-right: 20px

    &__list
      li
        span
          font-size: 12px


// .home-features
//   margin: 180px 0
//   &__bg-holder
//     position: relative
//     &::before
//       display: inline-block
//       content: ' '
//       position: absolute
//       z-index: 1
//       top: 50%
//       left: 0
//       right: 0
//       height: 60%
//       background: $lightBg
//       transform: translateY(-50%)
//   &__wrapper
//     position: relative
//     z-index: 2
//     display: flex
//     flex-wrap: wrap
//     margin-left: -15px
//     margin-right: -15px
//     margin-top: 35px
//   &__col
//     padding: 10px 15px
//     flex: 0 1 50%
//     max-width: 50%
//     min-width: 1px
//     min-height: 0
//
//   &-card
//     padding: 30px 25px
//     background: #FFFFFF
//     box-shadow: 0 0 30px 0 rgba(0,0,0,0.05)
//     border-radius: 3px
//     display: flex
//     align-items: center
//     &__image
//       flex: 0 1 40%
//       max-width: 40%
//       background: $lightBg
//       text-align: center
//       min-height: 240px
//       img
//         max-width: 100%
//     &__content
//       flex: 0 1 60%
//       max-width: 60%
//       padding-left: 25px
//       p
//         font-size: 14px
//         line-height: 1.42
//         color: $fontColorGray
//     &__name
//       font-size: 18px
//       font-weight: 500
//       margin: 1em 0


.home-features
  margin: 100px 0 0px
  &__bg-holder
    position: relative
    &::before
      display: inline-block
      content: ' '
      position: absolute
      z-index: 1
      bottom: 0
      left: 0
      right: 0
      background: $lightBg
      height: 220px
      // height: 85%
        // transform: translateY(43%)
  &__wrapper
    position: relative
    z-index: 2
    display: flex
    flex-wrap: wrap
    margin-left: -15px
    margin-right: -15px
    padding-bottom: 80px
    margin-top: 35px
  &__col
    padding: 10px 15px
    flex: 0 1 25%
    max-width: 25%
    min-width: 1px
    min-height: 0
    display: flex
    flex-direction: column

  &-card
    flex: 1 0 auto
    padding: 45px 15px 30px
    background: #FFFFFF
    box-shadow: 0 0 30px 0 rgba(0,0,0,0.05)
    border-radius: 3px
    &__image
      // background: $lightBg
      text-align: center
      min-height: 60px
      margin-left: auto
      margin-right: auto
      display: flex
      align-items: center
      justify-content: center
      img
        max-width: 100%
    &__content
      margin-top: 35px
      text-align: center
      max-width: 220px
      margin-left: auto
      margin-right: auto
      p
        font-size: 14px
        line-height: 1.42
        color: $fontColorGray
    &__name
      font-size: 18px
      font-weight: 500
      margin: 1em 0

+r($desktop)
  .home-features
    margin: 120px 0
    &__bg-holder
      &::before
        top: 50%
        bottom: auto
        height: 300px
        transform: translateY(-50%)
    &__wrapper
      padding-bottom: 0
    &__col
      flex-basis: 50%
      max-width: 50%

+r($mobile)
  .home-features
    margin: 70px 0 80px
    &__bg-holder
      &::before
        display: none
    &__wrapper
      padding-bottom: 0
    .t-center
      text-align: left
    &__col
      flex-basis: 100%
      max-width: 100%
    &-card
      display: flex
      align-items: center
      padding: 25px 15px
      &__content
        margin-top: 0
        margin-left: 20px
        text-align: left
        p
          margin: 0
      &__image
        margin-left: 0
        margin-right: 0
      &__name
        margin: 0 0 .5em





.home-benefits
  &__list
    /* blank class in html */
    /* used as scrollmonitor for custom animation */
    &.aos-animate
      /* show active one by one (removed) */
      // li:not(.is-disabled)
      li
        .home-benefits__list-icon
          opacity: 1
          transform: translateZ(0) scale(1)
        span
          opacity: 1
          transform: translateZ(0)
        &:nth-child(1)
          .home-benefits__list-icon, span
            transition-delay: .1s
        &:nth-child(2)
          .home-benefits__list-icon, span
            transition-delay: .15s
        &:nth-child(3)
          .home-benefits__list-icon, span
            transition-delay: .2s
        &:nth-child(4)
          .home-benefits__list-icon, span
            transition-delay: .25s
        &:nth-child(5)
          .home-benefits__list-icon, span
            transition-delay: .3s
        &:nth-child(6)
          .home-benefits__list-icon, span
            transition-delay: .35s
        &:nth-child(7)
          .home-benefits__list-icon, span
            transition-delay: .4s
        &:nth-child(8)
          .home-benefits__list-icon, span
            transition-delay: .45s
        &:nth-child(9)
          .home-benefits__list-icon, span
            transition-delay: .5s
        &:nth-child(10)
          .home-benefits__list-icon, span
            transition-delay: .55s
      /* shpw not active all by group */
      // li.is-disabled
      //   .home-benefits__list-icon
      //     opacity: 1
      //     transform: translateZ(0) scale(1)
      //   span
      //     opacity: 1
      //     transform: translateZ(0)
      // &[data-active-index="1"]
      //   li.is-disabled
      //     .home-benefits__list-icon, span
      //       transition-delay: .2s
      // &[data-active-index="2"]
      //   li.is-disabled
      //     .home-benefits__list-icon, span
      //       transition-delay: .25s
      // &[data-active-index="3"]
      //   li.is-disabled
      //     .home-benefits__list-icon, span
      //       transition-delay: .3s
      // &[data-active-index="4"]
      //   li.is-disabled
      //     .home-benefits__list-icon, span
      //       transition-delay: .35s
      // &[data-active-index="5"]
      //   li.is-disabled
      //     .home-benefits__list-icon, span
      //       transition-delay: .4s
      // &[data-active-index="6"]
      //   li.is-disabled
      //     .home-benefits__list-icon, span
      //       transition-delay: .45s
      // &[data-active-index="7"]
      //   li.is-disabled
      //     .home-benefits__list-icon, span
      //       transition-delay: .5s
      // &[data-active-index="8"]
      //   li.is-disabled
      //     .home-benefits__list-icon, span
      //       transition-delay: .55s
      // &[data-active-index="9"]
      //   li.is-disabled
      //     .home-benefits__list-icon, span
      //       transition-delay: .6s
      // &[data-active-index="10"]
      //   li.is-disabled
      //     .home-benefits__list-icon, span
         //       transition-delay: .65s
