.signup
  margin-top: 90px
  // margin-bottom: 150px
  &__content
    min-height: 500px
  &__box
    position: relative
    z-index: 2
    background: #FFFFFF
    box-shadow: 0 0 30px 0 rgba(0,0,0,0.05)
    border-radius: 3px
    padding-bottom: 45px
    display: flex
    flex-direction: column
    // min-height: calc(100vh - 250px)
    // min-height: calc(100vh - 90px)
    // overflow: hidden
    &--alt
      .btn--huge
        padding-top: 26px
        padding-bottom: 24px
      .icon-rifeng-sign
        margin-top: 20px
    &--h100
      min-height: calc(100vh - 90px - 90px)

  &__wrapper
    position: relative
    z-index: 2
    // overlay the cta buttons
    display: flex
    // align-items: center
    margin-left: -15px
    margin-right: -15px
    padding: 60px 100px 0px
    // why it was not tranistioned ?
    transition: .4s cubic-bezier(0.47, 0, 0.745, 0.715)
    &.fade-out
      // transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715)
      transform: translate3d(-20px, 0, 0) !important
      // transition-duration: .4s
      // transition-property: opacity,transform
      opacity: 0 !important

  &__left
    flex: 0 1 50%
    max-width: 50%
    min-width: 1px
    min-height: 0
    padding: 0 15px
    padding-right: 45px
    &--managers
      padding-right: 15px
    h2
      font-size: 28px
      max-width: 420px
    p
      max-width: 400px
  &__right
    flex: 0 1 50%
    max-width: 50%
    min-width: 1px
    min-height: 0
    padding: 0 15px
    &--longer
      flex-basis: 60%
      max-width: 60%
    &--400
      max-width: 400px
    &--no-pad
      h2
        margin-top: -10px
    a
      display: inline-block
      transition: color .25s $ease
      &:hover
        color: $colorBlue
    .t-paragraph
      strong
        color: $fontColor

  &__single-col
    padding: 0 15px
    flex: 0 1 100%
    max-width: 100%
    min-width: 1px
    min-height: 0

  &__intro
    padding-top: 50px
    .icon-rifeng-sign
      margin-top: 30px

  &__signs-row
    display: flex
    align-items: center
    margin-top: 30px
    .icon, img
      margin-top: 0
      margin-right: 40px
      &:last-child
        margin-right: 0


  &__avatar
    position: relative
    z-index: 1
    border-radius: 50%
    font-size: 0
    img
      max-width: 100%
      width: 100%
    &--big
      max-width: 270px
      max-height: 270px
    &--extra-small
      max-width: 90px
      max-height: 90px
    &--small
      max-width: 105px
      max-height: 105px
    &--onboarding
      max-width: 405px
      max-height: 100%
    &--row
      max-width: 245px
      max-height: 245px
      border: 20px solid white

    &-mask
      position: relative
      z-index: 1
      overflow: hidden
      border-radius: 50%
  &__heading-row
    display: flex
    align-items: center
    margin-bottom: 50px
    .signup__avatar
      flex: 0 0 90px

    h2
      margin-left: 25px


  &__managers
    display: flex
    margin-top: 65px

  &__manager
    position: relative
    width: 100%
    &:nth-child(1)
      z-index: 2
    &:nth-child(2)
      z-index: 1
      margin-left: -60px

    &-name
      max-width: 150px
      margin-top: 10px
      margin-left: auto
      margin-right: auto
      text-align: center
      font-size: 14px
      color: $fontColorGray

    &:hover
      .signup__hover-tooltip
        // opacity: 1
  &__image
    &-wrapper
      border-radius: 50%
      overflow: hidden
  &__hover
    &-tooltip
      position: absolute
      z-index: 2
      top: -27%
      left: 50%
      background: white
      min-width: 158px
      box-shadow: 0 0 20px 0 rgba(0,0,0,0.08)
      border-radius: 3px
      font-size: 13px
      color: $fontColorGray
      pointer-events: none
      // cursor: pointer
      // opacity: 0
      transform: translateX(-50%)
      transition: opacity .3s $ease
    &-wrapper
      padding: 22px 10px 19px 20px
      position: relative
      &::after
        display: inline-block
        content: ' '
        position: absolute
        bottom: -9px
        left: 50%
        transform: translateX(-50%)
        +arr(20,10,#fff,b)
      span
        display: block
        margin-top: 4px
        font-size: 18px
        font-weight: 500
        color: $colorBlue


  &__cta
    max-width: 360px
    width: 100%
    margin: 120px auto 0px

  &__cta-thanks
    margin-top: 50px
    margin-bottom: 50px

  &__form
    .ui-group
      &:last-child
        margin-bottom: 0

  &__progress
    padding-top: 70px
    &-name
      position: absolute
      top: -35px
      text-align: center
      // right: -21px
      // right: -34px
      right: -26px
      font-size: 14px
      color: $fontColorGray
      &--last
        right: 10px
    &-line
      position: relative
      background: #F6F7FB
      height: 15px
    &-fill
      position: absolute
      background: $colorBlue
      top: 0
      bottom: 0
      left: 0
      transition: width .25s $ease

  // navigation
  &__nav
    position: relative
    z-index: 1
    // margin-top: 40px
    margin-top: auto
    padding: 40px 100px 0
    display: flex
    align-items: center
    justify-content: space-between
    a
      cursor: pointer
    &--complete
      margin-top: 70px
      justify-content: center
    &--centered
      justify-content: center
      margin-top: 50px
    .btn--small
      min-width: 120px
    .btn--huge
      min-width: 145px
    // &-back
    //   display: flex
    //   align-items: center
    //   color: $colorBlue
    //   transform: translateY(1px)
    //   font-size: 0
    //   transition: color .25s $ease
    //   .ico
    //     font-size: 11px
    //     margin-right: 12px
    //     overflow: visible
    //     transform: translate(0,0px)
    //     transition: transform .25s $ease
    //   span
    //     display: inline-block
    //     font-weight: 500
    //     font-size: 12px
    //     transform: translateY(1px)
    //   &:hover
    //     color: $colorGreen
    //     .ico
    //       // fill: co
    //       // transform: translate(-5px,0px)

  &__sucess
    margin: 80px auto 50px
    // size: 370px 270px
    // background: $lightBg
    font-size: 0
    &-container
      flex: 1 0 auto
      display: flex
      flex-direction: column
      justify-content: center
      padding: 50px 100px 0
      text-align: center
      h2
        font-size: 28px
        margin-bottom: 0em
    .ico
      font-size: 147px
      color: $colorGreen

  &__datetime
    position: relative
    z-index: 2
    display: flex
    align-items: center
    margin-left: -10px
    margin-right: -10px
    transition: opacity .25s $ease
    &.is-disabled
      opacity: .5
      pointer-events: none
    &-col
      flex: 0 1 50%
      max-width: 50%
      // display: flex
      padding-left: 10px
      padding-right: 10px
      // .Select
      //   flex: 0 0 100%
      //   display: flex
      // .Select-control
      //   flex: 0 0 100%
      //   display: flex
      // .Select-multi-value-wrapper
      //   width: 100%
      //   display: block
    &-hint
      margin-top: 10px
      color: $fontColorGray
      font-size: 12px

  &__datepicker
    &.is-focused
      .SingleDatePickerInput_calendarIcon
        transform: rotate(180deg) translateY(-50%)

  &__email-instead
    position: relative
    z-index: 1
    margin-top: 90px
    .ui-group
      z-index: 1

  &__info
    font-size: 13px
    max-width: 420px
    color: $fontColorGray
    line-height: 1.53
    background: #f6f7fb
    padding: 24px
    margin-top: 25px

  &__note
    font-size: 16px
    line-height: 1.875
    color: $fontColorGray
    margin-bottom: 30px

  &__section
    position: relative
    margin-top: 65px
    &:first-child
      margin-top: 0

    &-heading
      font-size: 12px
      font-weight: 500
      line-height: 1.82
      color: $fontColorGray
      margin-bottom: 8px
    .ico-question-circle
      font-size: 20px
      vertical-align: middle
      margin-left: 5px
      margin-right: 5px
  &__checkboxes
    position: relative
    > label
      display: inline-block
      font-size: 13px
      color: rgba($fontColorGray, .5)
      margin: 10px 0
    .ui-checkbox
      margin-bottom: 10px
      &__label
        opacity: .5
        transition: opacity .25s $ease
      &.is-active, &:hover
        .ui-checkbox__label
          opacity: 1
      &.is-active
        + .ui-group
          display: block
      + .ui-group
        display: none
        margin-bottom: 10px


.signup-sucess
  &__grid
    display: flex
    margin-left: -15px
    margin-right: -15px
    &--centered
      // .signup-sucess
      //   &__left
      //     // margin-left: auto
      //   &__right
      //     max-width: 350px
      //     // margin-left: 50px
      //     margin-left: auto
      //     margin-right: auto

  &__left
    flex: 0 0 210px
    max-width: 210px
    padding: 0 15px
    margin-left: auto
    .signup__sucess
      margin: 30px auto

  &__right
    flex: 1 1 auto
    max-width: 570px
    padding: 0 15px
    margin-left: auto
    text-align: left

  &__links
    background: #f6f7fb
    margin: 22px 0
    padding: 20px 20px 20px 35px
    display: flex
    flex-direction: column
    a
      display: inline-block
      margin-bottom: 10px
      font-size: 14px
      line-height: 1.5
      font-weight: 500
      color: $fontColorGray
      transition: .25s $ease
      &:last-child
        margin-bottom: 0
      span
        color: $colorBlue
        transition: .25s $ease
      &:hover
        color: $colorBlueHover
        span
          color: $colorBlueHover



+r($desktop)
  .signup
    &__wrapper
      padding-left: 50px
      padding-right: 50px
    &__nav
      padding-left: 50px
      padding-right: 50px

+r($tablet)
  .signup
    &__wrapper
      flex-direction: column
      padding-top: 25px

    &__left
      flex: 0 0 auto
      max-width: 100%
      margin-top: 25px
      padding-right: 15px
      h2
        font-size: 22px
        margin-top: 20px
    &__right
      flex: 0 0 auto
      max-width: 100%
      margin-top: 30px

    &__intro
      padding-top: 20px

    &__cta
      margin-top: 80px

    &__cta-thanks
      margin-top: 35px
      margin-bottom: 35px

    &__nav
      margin-top: 30px

    &__datetime
      // margin-top: 45px

    &__email-instead
      margin-top: 80px

    &__sucess
      margin: 50px 0 50px
      // size: 270px 200px
      max-width: 147px
      text-align: left
      .ico
        font-size: 125px
      &-container
        padding-left: 50px
        padding-right: 50px
        // padding-left: 30px
        // padding-right: 30px

    &__info
      margin-bottom: 20px

    &__box--alt
      .icon-rifeng-sign
        margin-top: 0

  .signup-sucess
    &__grid
      display: block
    &__left
      margin-left: 0
      .signup__sucess
        margin-top: 0
        margin-left: 0
    &__right
      margin-left: 0

+r($mobile)
  .signup
    margin-top: 80px
    margin-bottom: 90px
    &__wrapper
      padding-left: 25px
      padding-right: 25px
    &__left
      margin-top: 0
      h2
        margin-top: 1em
    &__intro
      padding-top: 0
      .t-paragraph
        margin-top: 2em
      .icon-rifeng-sign
        margin-top: 15px
    &__cta
      margin-top: 50px
      max-width: 280px

    &__datetime
      display: block
      max-width: 250px
      &-col
        max-width: 100%
        padding-left: 10px
        padding-right: 10px
        margin-bottom: 35px

    &__info
      padding: 15px 10px

    &__sucess
      &-container
        padding-left: 25px
        padding-right: 25px
