.signed
  margin-top: 90px
  &__content
    text-align: center
    margin: 50px 0 auto
    h2
      font-size: 28px
  &__box
    // min-height: calc(100vh - 90px - 90px)
    min-height: 600px
    box-shadow: 0 0 30px 0 rgba(0,0,0,0.05)
    border-radius: 3px
    margin-bottom: 15px
    padding: 0 20px 70px
    display: flex
    flex-direction: column
    .ico
      font-size: 147px
      color: $colorGreen
  &__bottom-text
    font-size: 12px
    line-height: 1.5
    color: $fontColorGray
    a
      color: $cabinBlue

