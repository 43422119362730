// buttons
button
  display: inline-block
  -webkit-appearance: none
  border: 0
  box-shadow: none
  &:focus
    outline: none

.btn
  display: inline-block
  position: relative
  text-align: center
  cursor: pointer
  background: $colorBlue
  color: white
  border-radius: 3px
  line-height: 1
  font-weight: 500
  transition: .25s $ease
  padding: 10px 25px 10px
  &:focus, &:active
    outline: none
  &--block
    display: block
    width: 100%
  &--small
    font-size: 12px
    // box-shadow: 0 1px 3px 0 rgba(0,0,0,0.15)
    padding: 10px 25px
  &--huge
    font-size: 13px
    padding: 20px 25px 19px
  &--mega
    font-size: 14px
    padding: 24px 40px 21px
    line-height: 1.4
    color: rgba(white, .7)
    span
      color: white
  &:hover
    // background: $colorGreen
    background-color: $colorBlueHover
    color: white
    box-shadow: none

  &--iconed
    display: flex
    align-items: center
    justify-content: center
    .ico
      overflow: visible
      margin-left: 15px
      &-next-arrow
        font-size: 20px

.btn-gray
  border: 1px solid rgba($fontColorGray, .5)
  background: transparent
  color: $fontColorGray
  &:hover
    background: rgba($fontColorGray, .1)
    color: $fontColorGray

+r($mobile)
  .btn
    &--mega
      padding-left: 15px
      padding-right: 15px
