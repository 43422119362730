
.professionals
  background: #F6F7FB
  padding-top: 85px

  &__logos
    display: flex
    align-items: center
    justify-content: center
    margin-top: 65px
    padding-bottom: 100px
    &-el
      padding: 20px 40px
      mix-blend-mode: darken
      font-size: 0
      text-align: center
      .icon
        filter: saturate(0) opacity(50%)
        transition: filter .25s $ease
      span
        display: block
        text-align: center
        font-size: 10px
        line-height: 2
        color: $fontColorGray
      &:hover
        .icon
          filter: none

  &__slider
    transform: translateY(50%)
    .swiper-wrapper
      margin-left: -18px
      margin-right: -18px
  &__slide
    flex: 0 0 335px
    width: 335px
    padding: 18px

  &-card
    background: #FFFFFF
    box-shadow: 0 0 30px 0 rgba(0,0,0,0.05)
    border-radius: 3px
    display: flex
    align-items: center
    width: 100%
    padding: 35px 20px 30px
    &__avatar
      flex: 0 0 89px
      position: relative
      z-index: 1
      size: 89px
      border-radius: 50%
      overflow: hidden
      background: #FFFFFF
      font-size: 0
      box-shadow: 0 0 8px 0 rgba(0,0,0,0.14)
      img
        position: absolute
        top: 50%
        left: 50%
        width: 102%
        transform: translate(-50%,-50%)
    &__content
      margin-left: 40px
    &__name
      font-size: 18px
    &__position
      font-size: 14px
      line-height: 1.9
      color: $fontColorGray
    &__qualifications
      margin-top: 5px
      font-size: 12px
      line-height: 1.67
      color: $fontColorGray
      span
        display: block

+r($tablet)
  .professionals
    padding-top: 70px
    &__logos
      margin-top: 65px
      padding-bottom: 60px
      flex-wrap: wrap
      &-el
        padding: 20px

+r($mobile)
  .professionals
    padding-top: 50px
    .t-center
      text-align: left
    &__logos
      margin-top: 45px
      padding-bottom: 40px
      margin-left: 10px
      margin-right: 10px
      // flex-wrap: wrap
      &-el
        flex-basis: 50%
        flex-shrink: 1
        padding: 10px 5px
        &:first-child
          .icon
            transform: translateX(15px)
        &:last-child
          .icon
            // transform: translateX(-5px)
