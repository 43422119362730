// ICONS

// You might use sprite instead
// upload .png and @2x.png to /icons folder to create png sprite
// or upload .svg to create .svg sprite and use :use attr

// hint
// png sprite have .icon prefix
// svg sprtite have .ico prefix

// this section intended to be for custom icons which for some reason
// can't be parsed correctly or sprite is not required

.icon
  display: inline-block
  font-style: normal
  content: ' '

// reset for empty icons
.ico
  fill: currentColor
