.footer
  background: white
  transition: opacity .25s $ease
  &--hide
    opacity: 0
    pointer-events: none
    max-height: 50px
    overflow: hidden

  &__wrapper
    display: flex
    // align-items: center
    align-items: flex-start
    justify-content: space-between
    padding: 32px 0 28px 0
    color: $fontColorGray
    font-size: 11px
  &__logo
    display: flex
    align-items: center
    flex: 0 0 auto
    padding-bottom: 8px    
    font-size: 0
    .ico
      font-size: 17px
    span
      display: inline-block
      margin-left: 12px
      font-size: 11px
      padding-top: 3px
  &__info
    flex: 1 1 auto
    display: flex
    margin-left: auto
  &__col
    display: inline-block
    span
      display: block
      line-height: 1.72
    a
      color: $colorBlue
      transition: .25s $ease
      &:hover
        color: $colorBlueHover

+r($tablet)
  .footer
    &__wrapper
      display: flex
      flex-direction: column
      justify-content: center
      padding: 32px 0 28px 0
      color: $fontColorGray
      font-size: 11px
      gap: 18px

+r($mobile)
  .footer
    &__wrapper
      padding: 22px 0 18px 0
      justify-content: center
    &__logo
      padding-right: 0
      span
        margin-left: 12px
    &__info
      display: none
    