.faq
  margin: 150px 0
  h2
    text-align: center

  &-panel
    margin: 40px 0
    max-width: 770px
    margin-left: auto
    margin-right: auto
    background: #FFFFFF
    box-shadow: 0 0 30px 0 rgba(0,0,0,0.05)
    border-radius: 3px
    // padding: 20px 30px
    transition: .25s $ease
    &.is-active
      .faq-panel
        &__head
          color: $fontColor
          .ico
            transform: rotate(90deg)
    &:hover
      box-shadow: 0 0 20px 0 rgba(0,0,0,0.1)
      .faq-panel
        &__head
          color: rgba($fontColor, .7)

    &__head
      display: flex
      align-items: center
      cursor: pointer
      padding: 23px 30px
      color: $fontColorGray
      .ico
        flex: 0 0 auto
        margin-left: auto
        font-size: 8px
        // transform: rotate(-90deg)
        transition: .25s $ease

    &__name
      color: $cabinBlue
      font-weight: 500
      flex: 1 1 auto
      font-size: 13px
      line-height: 1.7
      padding-right: 20px
      transition: color .25s $ease

    &__content
      padding: 15px 30px 30px
      p
        font-size: 13px
        color: $fontColorGray
        line-height: 1.42
        // margin: 0
      a
        color: $colorBlue
        transition: .25s $ease
        &:hover
          color: $colorBlueHover
      &-inner
        padding: 20px 0

+r($desktop)
  .faq
    margin: 125px 0

+r($tablet)
  .faq
    &-panel
      &__content
        padding-bottom: 20px
        p
          margin-top: 0
          & + p
            margin-top: 1em


+r($mobile)
  .faq
    margin: 100px 0

    h2
      text-align: left
    &-panel
      margin: 30px 0
      &__head
        padding: 22px 15px
      &__name
        font-size: 14px

      &__content
        padding: 10px 15px 20px
