@font-face
  font-family: 'Eina01'
  src: url('../fonts/Eina01-Regular.otf') format("opentype")
  font-weight: 400
  font-style: normal

@font-face
  font-family: 'Eina01'
  src: url('../fonts/Eina01-Light.otf') format("opentype")
  font-weight: 300
  font-style: normal

@font-face
  font-family: 'Eina01'
  src: url('../fonts/Eina01-SemiBold.otf') format("opentype")
  font-weight: 500
  font-style: normal

@font-face
  font-family: 'Eina01'
  src: url('../fonts/Eina01-Bold.otf') format("opentype")
  font-weight: 700
  font-style: normal

@font-face
  font-family: "SF Pro Text"
  src: url('../fonts/SF-Pro-Text-Regular.otf') format('opentype')
  font-weight: 400

