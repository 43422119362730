.tabs-pane

  &__list
    position: relative
    z-index: 2
    display: flex
    // flex-wrap: wrap
    margin: 0
    padding: 0
    &::after
      /* fix shadow
      display: inline-block
      content: ' '
      position: absolute
      left: 0
      right: 0
      bottom: -30px
      height: 30px
      pointer-events: none
      background: white

  &__tab
    display: inline-block
    flex: 1 1 20%
    max-width: 20%
    font-size: 13px
    color: $fontColorGray
    border-top-left-radius: 3px
    border-top-right-radius: 3px
    text-align: center
    padding: 23px 15px 18px
    cursor: pointer
    transition-property: background, box-shadow
    transition-duration: .25s
    transition-timing-function: $ease
    &:hover
      box-shadow: 0px 0px 30px rgba(black, .05)
    &.is-selected
      font-weight: 500
      box-shadow: 0px 0px 30px rgba(black, .05)
      background: white

  &__panel
    position: relative
    z-index: 1
    opacity: .5
    background: #FFFFFF
    box-shadow: 0 0 30px 0 rgba(0,0,0,0.05)
    border-radius: 3px
    transition: .25s $ease
    &.is-active
      opacity: 1

+r($tablet)
  .tabs-pane
    &__panel
      opacity: 1
