.react-phone-number-input__row
  /* This is done to stretch `.rrui__select__options`
  /* to the full width of `.react-phone-number-input__phone`.
  /* Will be moved to `rrui.css` in version 2.x.
  position: relative
  /* This is done to stretch the contents of this component
  display: flex
  align-items: center

/* Overrides `width: 100%` setting from `react-resonsive-ui`

.react-phone-number-input__country
  width: auto

/* The phone number input stretches to fill all empty space

.react-phone-number-input__phone, .react-phone-number-input__country.rrui__select--expanded
  flex: 1

.react-phone-number-input__phone
  font-size: inherit

/* Will be moved to `rrui.css` in version 2.x.

.react-phone-number-input__country
  &.rrui__select--collapsed
    margin-right: 0.5em
  .rrui__select__autocomplete
    width: 100%
  /* This is done to stretch `.rrui__select__options`
  /* to the full width of `.react-phone-number-input__phone`
  /* rather than to the full width of `.rrui__select`
  /* because the `.rrui__select` is contracted
  /* as soon as a user selects a country which caused
  /* the `.rrui__select__options` to contract too
  /* while the list collapse animation is still in progress.
  /* The `position: relative` container is now `.react-phone-number-input`.
  /* The `:not(.react-phone-number-input__country--native-expanded)` selector
  /* is required because native `<select/>` is positioned absolutely.
  position: static
  &:not(.react-phone-number-input__country--native-expanded) .rrui__input
    /* This is done to stretch `.rrui__select__options`
    /* to the full width of `.react-phone-number-input__phone`
    /* rather than to the full width of `.rrui__select`
    /* because the `.rrui__select` is contracted
    /* as soon as a user selects a country which caused
    /* the `.rrui__select__options` to contract too
    /* while the list collapse animation is still in progress.
    /* The `position: relative` container is now `.react-phone-number-input`.
    /* The `:not(.react-phone-number-input__country--native-expanded)` selector
    /* is required because native `<select/>` is positioned absolutely.
    position: static

/* Will be moved to `rrui.css` in version 2.x.

/* Will be moved to `rrui.css` in version 2.x.

/* Will be moved to `rrui.css` in version 2.x.

.react-phone-number-input
  .rrui__select__arrow
    margin-top: 0.3em
    margin-left: 0.3em
  .rrui__select__option
    display: flex
    align-items: center
  .rrui__select__option-icon
    margin-bottom: 0.1em

/* Will be moved to `rrui.css` in version 2.x.

/* Will be moved to `rrui.css` in version 2.x.

.react-phone-number-input__icon
  /* The flag icon size is 4x3 hence the exact `width` and `height` values
  width: 19px
  height: 14px
  border: 1px solid rgba(100, 108, 119, 0.5)
  /* Makes sure `width`x`height` is exactly `4x3` and `border` width is not included in it
  box-sizing: content-box

.react-phone-number-input__icon--international
  /* The international icon size is square
  /* and also has no border hence the `2 * 1px` `width` and `height` compensation
  width: calc(0.93em + 2px)
  height: calc(0.93em + 2px)
  /* The international icon size is square hence the exact `padding` value
  /* for precise alignment with `4x3` sized country flags.
  padding-left: 0.155em
  padding-right: 0.155em
  border: none

.react-phone-number-input__error
  margin-left: calc(1.24em + 2px + 0.3em + 0.35em + 0.5em)
  margin-top: calc(0.3rem)
  color: #D30F00

.react-phone-number-input__icon-image
  max-width: 100%
  max-height: 100%

/* Removes `<input type="number"/>` up/down arrows in Webkit browsers.

.react-phone-number-input__ext-input
  &::-webkit-inner-spin-button, &::-webkit-outer-spin-button
    margin: 0 !important
    -webkit-appearance: none !important
    -moz-appearance: textfield !important
  width: 3em

.react-phone-number-input__ext
  white-space: nowrap
  margin-left: 0.5em

.react-phone-number-input__ext-input
  margin-left: 0.5em

/* The phone number input should shrink
 * to make room for the extension input

.react-phone-number-input__phone
  min-width: 0

/* Native `<select/>`.

.react-phone-number-input__country--native
  position: relative
  align-self: stretch
  display: flex
  align-items: center
  margin-right: 0.5em

.react-phone-number-input__country-select
  position: absolute
  top: 0
  left: 0
  height: 100%
  width: 100%
  z-index: 1
  border: 0
  opacity: 0
  cursor: pointer

.react-phone-number-input__country-select-arrow
  display: block
  content: ''
  width: 0
  height: 0
  margin-bottom: 0.1em
  margin-top: 0.3em
  margin-left: 0.3em
  border-width: 0.35em 0.2em 0 0.2em
  border-style: solid
  border-left-color: transparent
  border-right-color: transparent
  color: #B8BDC4
  opacity: 0.7
  transition: color 0.1s

.react-phone-number-input__country-select:focus + .react-phone-number-input__country-select-arrow
  color: #03B2CB

/* Remove "--native" for v 2.x

.react-phone-number-input__phone--native
  height: calc(0.3rem * 6)
  outline: none
  border-radius: 0
  padding: 0
  appearance: none
  border: none
  border-bottom: 1px solid #C5D2E0
  transition: border 0.1s
  &:focus
    border-color: #03B2CB

/* Remove "--native" for v 2.x

.react-phone-number-input__phone--disabled
  cursor: default

.react-phone-number-input__phone--invalid
  border-color: #EB2010
  &:focus
    border-color: #EB2010

/* Remove "--native" for v 2.x
/* Overrides Chrome autofill yellow background color

.react-phone-number-input__phone--native:-webkit-autofill
  box-shadow: 0 0 0 1000px white inset

/*===========================================================
 *= Inputs                                                    =
 *===========================================================

.rrui__input-field, .rrui__select__button
  border: none
  // border-bottom: 1px solid #C5D2E0
  transition: border 0.1s

.rrui__input-field:focus, .rrui__select__button:focus, .rrui__select__native-expanded:focus + .rrui__select__button
  // border-bottom-color: #03B2CB

/*===========================================================
 *= Input                                                     =
 *===========================================================

.rrui__input
  /* `position: relative` is for the floating label absolute positioning
   * and also for `.rrui__select__options width` sizing
  position: relative
  height: calc(0.3rem * 6)

.rrui__input-label
  height: calc(0.3rem * 6)

/* `.rrui__input:not(.rrui__input--multiline)`
 * would result in issues with this CSS selector being
 * too specific and therefore taking higher priority

.rrui__input--multiline
  height: auto

/* For native `<select/>` in Safari

.rrui__select__native
  min-height: calc(0.3rem * 6)

.rrui__input-error
  margin-top: calc(0.3rem)
  color: #D30F00

/*===========================================================
 *= Input field                                               =
 *===========================================================

.rrui__input-field
  outline: none
  border-radius: 0
  /* Some web browsers add <input/> padding by default
  padding: 0
  appearance: none

.rrui__input-field--invalid
  border-color: #EB2010
  &:focus
    border-color: #EB2010

.rrui__input-field--disabled
  cursor: default

/*===========================================================
 *= Buttons                                                   =
 *===========================================================

/* Reset the default `<button/>` styles

.rrui__select__button, .rrui__select__option, .rrui__select__close
  margin: 0
  padding: 0
  white-space: nowrap
  outline: none
  appearance: none
  border: none
  background: none
  cursor: pointer
  font-size: inherit
  font-family: inherit
  font-weight: inherit
  font-style: inherit

/*===========================================================
 *= Select                                                    =
 *===========================================================

.rrui__expandable
  opacity: 0
  pointer-events: none
  transform: scaleY(0)
  transform-origin: 50% top 0px
  /* 250ms - too slow for short lists
  transition: all 150ms cubic-bezier(0.76, 0.04, 0.46, 0.75) 0ms
  /* prevent white background showing through the transparent border
  /* http://stackoverflow.com/questions/7473200/transparent-border-expands-background-color-image
  background-clip: padding-box
  -webkit-background-clip: padding-box

.rrui__expandable--expanded
  opacity: 1
  pointer-events: auto
  transform: scaleY(1)
  transform-origin: 50% top 0px
  transition: all 150ms cubic-bezier(0.23, 1, 0.32, 1) 0ms

.rrui__expandable--overlay
  position: absolute
  z-index: 1

.rrui__expandable__content
  /*
   *The inner block's `opacity` is animated too
   *(it fades in and out by itself too).
   *This is done to fade out the inner block when the dropdown is collapsed
   *therefore hiding an otherwise bizarre and unwanted visual clutter
  opacity: 0
  /* Mitigates inner content overlapping with itself creating a feeling of a mess
  transition: opacity 150ms ease-out

.rrui__expandable__content--expanded
  opacity: 1

.rrui__shadow
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12)

.rrui__select
  user-select: none

.rrui__select--menu
  position: relative

.rrui__select__options
  margin: 0
  padding-left: 0
  padding-top: calc(2 * 0.3rem)
  padding-bottom: calc(2 * 0.3rem)
  overflow-y: auto
  background-color: white
  list-style-type: none
  overflow-x: hidden
  box-sizing: border-box

.rrui__expandable--left-aligned
  left: 0

.rrui__expandable--right-aligned
  right: 0

.rrui__select__options--left-aligned
  left: calc(0.3rem * 4 * -1)

.rrui__select__options--right-aligned
  right: calc(0.3rem * 4 * -1)

.rrui__select__options:not(.rrui__select__options--menu)
  width: calc(100% + 0.3rem * 4 * 2)

.rrui__select__options--downward
  /* When an html page is overflown by a long list
   * then this bottom margin helps to see that
   * there's no more options left out being clipped or something
  margin-bottom: 1em

.rrui__select__options--upward
  bottom: 100%
  /* When an html page is overflown by a long list
   * then this bottom margin helps to see that
   * there's no more options left out being clipped or something
  margin-top: 1em

.rrui__select__option
  display: block
  width: 100%
  padding-left: calc(0.3rem * 4)
  padding-right: calc(0.3rem * 4)
  padding-top: calc(0.3rem * 1)
  padding-bottom: calc(0.3rem * 1)
  box-sizing: border-box
  text-align: left
  white-space: nowrap
  &:hover
    background-color: #FBFDFD

.rrui__select__button--disabled, .rrui__select__option--disabled
  cursor: default

.rrui__select__option-icon
  margin-right: calc(0.3rem * 3)
  flex-shrink: 0

.rrui__select__options--upward
  transform-origin: 50% bottom 0px

.rrui__select__option--focused
  background-color: #ECF1F6
  &:hover
    background-color: #EFF3F8

.rrui__select__option:active
  background-color: #3678D1
  color: white

.rrui__select__option-icon
  margin-right: 0.5em

.rrui__select__toggler, .rrui__select__button, .rrui__select__autocomplete
  height: 100%
  box-sizing: border-box

.rrui__select__button
  // border-bottom: 1px solid #C5D2E0
  transition: border 0.1s
  /* Because Chrome adds `text-align: center` for all buttons
  text-align: left
  &:focus
    border-bottom-color: #03B2CB

.rrui__select__native-expanded:focus + .rrui__select__button
  border-bottom-color: #03B2CB

.rrui__select__button:active
  color: #03B2CB

.rrui__select__button--expanded
  color: #3678D1

.rrui__select__button--empty
  color: #888C91

.rrui__select__button, .rrui__select__option
  color: inherit

/* Not introducing an animation on `.rrui__select__option`
 * because it would introduce a little clutter due to
 * focused option animation in autocomplete mode

.rrui__select__button
  transition: color 30ms ease-out

.rrui__select__selected-content
  display: flex
  align-items: center

.rrui__select__selected-label
  flex: 1 0 auto
  overflow: hidden
  text-overflow: ellipsis

.rrui__select__arrow
  width: 0
  height: 0
  margin-left: 0.35em
  margin-top: 0.1em
  margin-bottom: 0.1em
  opacity: 0.7
  transition: opacity 100ms ease-out
  /* border-width: arrow_height arrow_width/2 0 arrow_width/2
  border-width: 0.35em 0.2em 0 0.2em
  border-style: solid
  border-left-color: transparent
  border-right-color: transparent
  color: rgba($fontColorGray, .5)

.rrui__select__arrow--expanded
  opacity: 0.3

.rrui__select__native-expanded
  position: absolute
  left: 0
  z-index: 1
  opacity: 0
  cursor: pointer

.rrui__select__close
  /*
   *Set `display` to `block` for fullscreen `.rrui__expandable` design.
   *display      : block;
  display: none
  position: fixed
  top: 0
  right: 0
  z-index: 1

.rrui__select__close-icon
  margin-top: calc(0.3rem * 2 + 4px)
  margin-right: calc(0.3rem * 2 + 4px)
  width: calc(0.3rem * 5)
  height: calc(0.3rem * 5)


/* Custom
.rrui__select__arrow
  // display: none

/* disallow select componenet
.rrui__select__button
  // pointer-events: none
