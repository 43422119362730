.pricing-scope
  margin-top: 60px
  &__holder
    max-width: 770px
    margin-left: auto
    margin-right: auto
  &__box
    position: relative
    overflow: hidden
    background: #FFFFFF
    box-shadow: 0 0 30px 0 rgba(0,0,0,0.05)
    border-radius: 3px
    padding: 70px 70px 50px
    color: $cabinBlue
    &--collumned
      flex: 1 0 auto
      display: flex
      flex-direction: column
      padding: 48px 40px 40px
      .pricing-scope
        &__icon
          flex-basis: 90px
          margin-bottom: 6px
          display: flex
          flex-direction: column
          justify-content: flex-end

        &__description
          display: flex
          /*align-items: center*/
          /*justify-content: center*/
          /*min-height: 72px*/
          vertical-align: middle
        &__list
          margin: 30px -15px 45px
          &-col
            flex-basis: 100%
            max-width: 100%
        &__header
          font-weight: 500
          font-size: 13px
          margin-left: 14px
          margin-bottom: 7px
        &__names
          flex: 0 0 auto
        &__options
          margin-top: auto
          flex: 0 0 auto
          line-height: 18px
          &__text
            font-size: 12px
            min-height: 18px
            color: $fontColorGray
            .ico-question-circle
              margin-left: 6px
              font-size: 12px
              vertical-align: middle
              transform: translateY(-2px)
          &__slider
            padding: 16px 4px 12px 4px
            margin-left: 4%
        &__toggle
          padding-left: 2px
          margin-top: 8px
          vertical-align: middle
          display: flex
          flex-direction: row
          align-items: center
          &-label
            font-size: 12px
            color: $fontColorGray
            font-weight: 400
            margin-left: 4px

        &__price
          // margin-top: auto
          padding-right: 0
          padding-top: 10px
          &-starting
            min-height: 11px
        // &__description
        //   max-width: 100%

  &__grid
    display: flex
    margin-left: -15px
    margin-right: -15px
  &__col
    flex: 0 1 50%
    max-width: 50%
    min-width: 1px
    min-height: 0
    display: flex
    flex-direction: column
    padding: 15px

    &-centered
      flex: 0 1 770px
      max-width: 770px
      margin: 0 auto
      .pricing-scope
        &__box
          padding: 60px
        &__names
          flex: 1 0 auto
  &__head
    display: flex
    // align-items: center
  &__icon
    flex: 0 0 100px
    max-width: 100%
    min-width: 1px
    min-height: 0
  &__names
    flex: 1 1 auto

  &__name
    color: $cabinBlue
    font-size: 18px
    font-weight: 500
    line-height: 1.5
  &__description
    color: $fontColorGray
    font-size: 13px
    line-height: 1.56
    /*margin-top: 10px*/
    max-width: 300px

  &__tag
    position: absolute
    top: 35px
    right: -45px
    transform: rotate(45deg)
    font-size: 10px
    color: white
    font-weight: 500
    text-transform: uppercase
    letter-spacing: 0.071em
    text-align: center
    background: $colorBlue
    padding: 10px 50px 8px
    pointer-events: none
  &__price
    position: relative
    flex: 0 0 auto
    max-width: 100%
    padding-right: 30px
    &-main
      color: $cabinBlue
      position: relative
      font-size: 18px
      font-weight: 500
      .ico-question-circle
        font-size: 15px
        margin-left: 6px
        vertical-align: middle
        transform: translateY(-2px)
        // position: absolute
        // right: -23px
        // top: 6px

    &-for
      font-size: 12px
      color: $fontColorGray
      font-weight: 400
      margin-top: 5px
      margin-left: 4px
    &-description
      font-size: 12px
      color: $fontColorGray
      font-weight: 400
      margin-top: 5px
      margin-left: 0px
    &-saving
      font-size: 13px
      color: $fontColorGray
      font-weight: 400
      margin-top: 5px
      min-height: 13px
    // &-saving
    //   padding-top: 3px
    //   font-size: 14px
    //   min-height: 19px
    //   color: $fontColorGray
    &-starting
      font-size: 10px
      color: $fontColorGray
      margin-bottom: 8px
    &--mobile
      display: none
      .pricing-scope__price-main 
        margin-top: 12px
    .Select
      text-align: left
      margin-top: 15px
      min-width: 110px
    &--right-aligned
      text-align: right
      padding-top: 0

  &__tooltip
    position: absolute
    right: 0
    top: 5px
    font-size: 0
    .ico
      font-size: 17px
      color: $colorBlue

  &__list
    // display: flex
    margin-top: 60px
    margin-left: -15px
    margin-right: -45px
    text-align: left

    &-col
      padding: 0 15px
    &-el
      // margin-bottom: 10px
      display: flex
      span
        display: inline-block
        margin-left: 12px
        font-size: 12px
        line-height: 26px
        color: $fontColorGray
      strong
        color: $cabinBlue
      a
        display: inline-block
        color: $colorBlue
        font-weight: 500
        transition: .25s $ease
        &:hover
          color: $colorBlueHover
      .ico
        flex: 0 0 auto
        color: $colorGreen
        font-size: 8px
        &-check
          transform: translateY(.5em)
        &-question-circle
          color: $colorBlue
          margin-left: 6px
          font-size: 12px
          vertical-align: middle
          transform: translateY(-1px)
  &__showmore
    display: none
  &__cta
    margin-top: 35px
    .btn
      padding: 16px 10px
      font-size: 13px
      color: #FFFFFF
      // +text-overflow
  &__promo
    margin-top: 35px
    font-size: 12px
    line-height: 1.67
    text-align: center
    color: $fontColorGray
    background: #f6f7fb
    padding: 16px 15px 14px
    border-radius: 3px
    margin-left: -25px
    margin-right: -25px
    span
      color: $colorBlue
      font-weight: 500

  &__more
    text-align: center
    // margin: 50px 0 25px
    margin: 60px 0 10px
    /* keep the scape event when no link provided */
    min-height: 14px
    align-items: center
    justify-content: center
    display: flex

  &__compare
    margin: 25px 0
    display: flex
    align-items: center
    justify-content: center
    text-align: center
    .learn-more
      .ico
        transform: translate(0, 1px) rotate(90deg)
      &:hover
        .ico
          // transform: translate(0px, 3px) rotate(90deg)

+r($desktop)
  .pricing-scope
    margin-top: 100px
    &__grid
      flex-wrap: wrap
      justify-content: center

    &__col
      flex: 0 1 50%
      max-width: 50%
      &-centered
        flex: 0 1 100%
        max-width: 100%

+r($tablet)
  .pricing-scope
    margin: 0 0 80px
    &__grid
      max-width: 400px
      flex-direction: column
      margin-left: auto
      margin-right: auto
    &__head
      display: block
    &__col
      flex: 0 0 auto
      max-width: 100%
      padding: 30px 0

      &-centered
        // flex: 0 1 770px
        // max-width: 770px
        // margin: 0 auto
        .pricing-scope
          &__box
            padding: 45px 30px 35px
          &__icon
            flex: 1 1 auto
        .pricing-scope__price
          display: none
          &--mobile
            display: block
    &__compare
      display: none

    &__holder
      margin-top: 30px

    &__price
      // display: none
      &--mobile
        display: block
        .Select
          width: 110px

    &__tooltip
      top: 15px

    &__promo
      margin-left: 15px
      margin-right: 15px

    &__box
      flex: 1 0 auto
      display: flex
      flex-direction: column
      padding: 45px 30px 35px
      //text-align: center
      &:not(.pricing-scope__box--collumned)
        .pricing-scope
          &__head
            flex-direction: column
          &__icon
            flex-basis: 62px
            margin-bottom: 25px
          &__description
            display: flex
            align-items: center
            justify-content: center
            min-height: 50px
            vertical-align: middle
          &__list
            margin: 30px -15px 45px
            margin-left: auto
            margin-right: auto
            &-col
              flex-basis: 100%
              max-width: 100%

          &__names
            flex: 0 0 auto
          &__price
            display: none
            // margin-top: auto
            // margin-left: auto
            // margin-right: auto
            // text-align: center
            // padding-right: 27px
            padding-right: 0
            padding-top: 10px
            padding-bottom: 10px
            &--tooltip
              padding-right: 27px
            &--mobile
              display: block
              max-width: 110px
          &__description
            max-width: 100%

+r($mobile)
  .pricing-scope
    &__list
      margin: 30px -15px 45px
      flex-wrap: wrap
      margin-left: auto
      margin-right: auto
      &-col
        flex-basis: 100%
        max-width: 100%

    &__showmore
      cursor: pointer
      display: block
      font-size: 12px
      font-weight: 500
      color: $cabinBlue
      position: absolute
      width: 100%
      bottom: -10px
      padding-top: 40px
      padding-bottom: 10px
      margin: 0 15px
      background-position: 50% 50%
      background-image: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 40%, rgba(255,255,255,0.896796218487395) 50%, rgba(255,255,255,0.7483368347338936) 80%, rgba(255,255,255,0) 100%)
      img
        margin-right: 8px

    &__box
      &--collumned
        .pricing-scope
          &__description
            padding-left: 0
            padding-right: 0

