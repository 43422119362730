
.pricing-table
  position: relative
  padding: 50px 0
  margin: 120px 0 0px
  &::after
    display: inline-block
    content: ' '
    position: absolute
    top: 0
    left: 0
    right: 0
    height: 50%
    background: #F6F7FB url('../images/pricingTableBg.png') no-repeat right center
    z-index: 1
  > .container
    position: relative
    z-index: 2

  &__wrapper
    margin: -50px 0 0px 0

  &__table
    position: relative
    z-index: 2
    overflow: visible
    margin: 25px 0 0
    &::after
      // mind top and bottom offsets
      // for the proper box-shadow above table
      display: inline-block
      content: ' '
      position: absolute
      z-index: 3
      top: 53px
      background: transparent
      pointer-events: none
      bottom: 0px
      // right: 4px
      right: 0px
      width: 155px
      border-radius: 3px
      box-shadow: 0 0 20px 0 rgba(0,0,0,0.05)

  &__thead
    position: relative
    z-index: 2
    display: flex
    // justify-content: flex-end
    // align-items: flex-end

    &-wrapper
      position: relative
      display: flex
      justify-content: flex-end
      align-items: flex-end
      margin-left: auto
      // box-shadow: 0 -6px 30px 0 rgba(0,0,0,0.05)
      transform: translateY(55px)
      &::after
        /* box shadow fix
        display: inline-block
        content: ' '
        position: absolute
        bottom: -13px
        left: 0
        right: 0
        height: 13px
        background: white

    .pricing-table__td
      width: 155px
      background: white
      padding-top: 18px
      padding-bottom: 13px
      min-height: 126px
      &::after
        display: none
        // border-bottom: 0

      &:first-child
        border-top-left-radius: 3px
        .pricing-table__head
          // height: calc(120px - 13px - 13px)
          .icon
            margin-top: 17px
          &-name
            margin-top: 25px
      &:nth-child(2)
        border-top-left-radius: 3px
        border-top-right-radius: 3px
      &:last-child
        // border-top-left-radius: 3px
        border-top-right-radius: 3px
        // transform: translateX(-4px)
        padding-bottom: 0
        .pricing-table__head
          // height: calc(120px - 13px)


  &__tbody
    box-shadow: 0 0 30px 0 rgba(0,0,0,0.05)
    border-radius: 3px
    position: relative
    padding-top: 60px
    background-color: #ffffff
    overflow: hidden
    .pricing-table__td
      max-height: 60px
  &__tr
    display: flex
    background: white
    // align-items: center
    padding-left: 25px
    .pricing-table__td
      &:first-child
        &::after
          left: 13px
      &:nth-child(2), &:nth-child(3), &:nth-child(4)
        flex: 0 0 auto
        width: 155px
      &:nth-child(4)
        // transform: translateX(-4px)
        // &::after
        //   left: 4px
    &:last-child
      .pricing-table__td
        &::after
          display: none
          // border-bottom: 0

  &__td
    position: relative
    padding: 17px 13px
    &::after
      display: inline-block
      content: ' '
      position: absolute
      bottom: 0
      left: 0
      right: 0
      background: rgba($fontColorGray, .5)
      height: 1px

    &--primary
      flex: 1 1 auto
      display: flex
      align-items: center
      .ico-question-circle
        margin-left: 6px
        color: $colorBlue
        font-size: 17px
        transform: translateY(3px)
    &-content
      display: inline-block
      font-size: 12px
      color: $fontColorGray
      text-align: center
      line-height: 1.41
      width: 100%
      transform: translateY(-2px)
      .ico-question-circle
        margin-left: 4px
        transform: translateY(2px)


  &__head
    text-align: center
    .icon
      // transform: scale(.6)
      // height: 60px
      // transform-origin: center top
      // filter: saturate(0)
    &-name
      font-size: 12px
      color: $fontColorGray
      line-height: 1.27
      // word-spacing: 100vw
      // min-height: 33px
      margin-top: 25px
      margin-bottom: 10px
    &-tag
      font-size: 10px
      color: white
      font-weight: 500
      text-transform: uppercase
      letter-spacing: 0.071em
      text-align: center
      background: $colorBlue
      margin-top: 20px
      padding: 10px 0px 8px
      margin-left: -13px
      margin-right: -13px
    &--popular
      padding-top: 15px
      .icon
        // transform: scale(.7)
        // margin-top: -10px
        // margin-bottom: 15px
        // filter: none
        // height: 62px
      .pricing-table__head
        &-name
          font-weight: 500
          // margin-top: -20px
          margin-left: -10px
          margin-right: -10px
          word-spacing: normal
          // margin-bottom: 0
          color: $colorBlue

  &__tag
    display: inline-block
    background: #63719D
    border-radius: 3px
    color: white
    font-size: 10px
    font-weight: 500
    text-transform: uppercase
    padding: 6px 8px 4px
    margin-right: 10px
    min-width: 43px
    text-align: center
  &__name
    display: inline-block
    font-size: 12px
    color: $fontColorGray
    transform: translateY(1px)

  &__checkmark
    display: flex
    align-items: center
    justify-content: center
    background: $colorGreen
    border-radius: 50%
    width: 26px
    height: 26px
    margin-right: 20px
    color: white
    margin-left: auto
    margin-right: auto
    .ico
      font-size: 11px
      transform: translate(.5px,0)


.pricing-table-legend
  position: relative
  z-index: 3
  display: flex
  // align-items: center
  min-height: 52px
  &__texts
    flex: 1 1 auto
    padding-right: 30px
    padding-left: 38px
    display: flex
    align-items: center
    .pricing-table__name
      margin-right: 18px
  &__cta
    flex: 0 0 155px
    display: flex
    align-items: center
    justify-content: center
    margin-left: auto
    width: 155px
    text-align: center
    background: white
    border-bottom-left-radius: 3px
    border-bottom-right-radius: 3px
    // transform: translateX(-4px)
    // box-shadow: 0 0 30px 0 rgba(0,0,0,0.05)
    &:last-child
      background: transparent

+r($desktop)
  .pricing-table
    margin: 70px 0 100px
    &__table
      &::after
        width: 125px
    &__thead
      .pricing-table__td
        width: 125px
    &__tr
      padding-left: 10px
      .pricing-table__td
        &:nth-child(2), &:nth-child(3), &:nth-child(4)
          width: 125px

    &__checkmark
      width: 22px
      height: 22px
      .ico
        font-size: 10px


  .pricing-table-legend
    &__texts
      padding-left: 23px
    &__cta
      flex-basis: 125px

+r($tablet)
  .pricing-table
    display: none
