.rc-slider
  position: relative
  height: 25px
  padding: 5px 0
  width: 100%
  border-radius: 10px
  touch-action: none
  box-sizing: border-box
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
  *
    box-sizing: border-box
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)

.rc-slider-rail
  position: absolute
  width: 105%
  background-color: #8790A1
  height: 1px
  margin-top: 2px
  margin-left: -5%

.rc-slider-track
  position: absolute
  left: 0
  border-radius: 10px
  background-color: rgba($colorBlue, .1)

.rc-slider-handle
  position: absolute
  margin-left: -8px
  margin-top: -5px
  width: 16px
  height: 16px
  cursor: pointer
  cursor: -webkit-grab
  cursor: grab
  border-radius: 50%
  // border: solid 2px #96dbfa
  background-color: $fontColorGray
  touch-action: pan-x
  transition: background .25s $ease

  box-shadow: 4px 0 8px 0 rgba(0,0,0,0.11)
  &:focus
    // border-color: #57c5f7
    // box-shadow: 0 0 0 5px #96dbfa
    outline: none

.rc-slider-handle-click-focused:focus
  border-color: #96dbfa
  box-shadow: unset

.rc-slider-handle
  background-color: $colorBlue
  &:hover
    border-color: #57c5f7
  &:active
    background-color: $colorBlue
    border-color: #57c5f7
    // box-shadow: 0 0 5px #57c5f7
    cursor: -webkit-grabbing
    cursor: grabbing

.rc-slider-mark
  position: absolute
  top: 18px
  left: 0
  width: 100%
  font-size: 12px

.rc-slider-mark-text
  position: absolute
  display: inline-block
  vertical-align: middle
  text-align: center
  cursor: pointer
  color: #999

.rc-slider-mark-text-active
  color: #666

.rc-slider-step
  position: absolute
  width: 100%
  height: 4px
  background: transparent

.rc-slider-dot
  position: absolute
  bottom: -3px
  margin-left: -4px
  width: 8px
  height: 8px
  border: 1px solid #7A8597
  background-color: #fff
  cursor: pointer
  border-radius: 50%
  vertical-align: middle

.rc-slider-dot-active
  border-color: #7A8597

.rc-slider-disabled
  background-color: #e9e9e9
  .rc-slider-track
    background-color: #ccc
  .rc-slider-handle, .rc-slider-dot
    border-color: #ccc
    box-shadow: none
    background-color: #fff
    cursor: not-allowed
  .rc-slider-mark-text, .rc-slider-dot
    cursor: not-allowed !important

.rc-slider-vertical
  width: 14px
  height: 100%
  padding: 0 5px
  .rc-slider-rail
    height: 100%
    width: 4px
  .rc-slider-track
    left: 5px
    bottom: 0
    width: 4px
  .rc-slider-handle
    margin-left: -5px
    margin-bottom: -7px
    touch-action: pan-y
  .rc-slider-mark
    top: 0
    left: 18px
    height: 100%
  .rc-slider-step
    height: 100%
    width: 4px
  .rc-slider-dot
    left: 2px
    margin-bottom: -4px
    &:first-child, &:last-child
      margin-bottom: -4px

.rc-slider-tooltip-zoom-down-enter, .rc-slider-tooltip-zoom-down-appear, .rc-slider-tooltip-zoom-down-leave
  animation-duration: .3s
  animation-fill-mode: both
  display: block !important
  animation-play-state: paused

.rc-slider-tooltip-zoom-down-enter.rc-slider-tooltip-zoom-down-enter-active, .rc-slider-tooltip-zoom-down-appear.rc-slider-tooltip-zoom-down-appear-active
  animation-name: rcSliderTooltipZoomDownIn
  animation-play-state: running

.rc-slider-tooltip-zoom-down-leave.rc-slider-tooltip-zoom-down-leave-active
  animation-name: rcSliderTooltipZoomDownOut
  animation-play-state: running

.rc-slider-tooltip-zoom-down-enter, .rc-slider-tooltip-zoom-down-appear
  transform: scale(0, 0)
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1)

.rc-slider-tooltip-zoom-down-leave
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06)

@keyframes rcSliderTooltipZoomDownIn
  0%
    opacity: 0
    transform-origin: 50% 100%
    transform: scale(0, 0)

  100%
    transform-origin: 50% 100%
    transform: scale(1, 1)


@keyframes rcSliderTooltipZoomDownOut
  0%
    transform-origin: 50% 100%
    transform: scale(1, 1)

  100%
    opacity: 0
    transform-origin: 50% 100%
    transform: scale(0, 0)


.rc-slider-tooltip
  position: absolute
  left: -9999px
  top: -9999px
  visibility: visible
  box-sizing: border-box
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
  *
    box-sizing: border-box
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)

.rc-slider-tooltip-hidden
  display: none

.rc-slider-tooltip-placement-top
  padding: 4px 0 8px 0

.rc-slider-tooltip-inner
  padding: 6px 2px
  min-width: 24px
  height: 24px
  font-size: 12px
  line-height: 1
  color: #fff
  text-align: center
  text-decoration: none
  background-color: #6c6c6c
  border-radius: 6px
  box-shadow: 0 0 4px #d9d9d9

.rc-slider-tooltip-arrow
  position: absolute
  width: 0
  height: 0
  border-color: transparent
  border-style: solid

.rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow
  bottom: 4px
  left: 50%
  margin-left: -4px
  border-width: 4px 4px 0
  border-top-color: #6c6c6c
