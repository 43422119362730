.hellosign
  border-style: dashed
  border-width: 1px
  border-color: #646c77
  background-color: #f6f7fb
  color: #646c77
  display: flex
  align-items: center
  justify-content: center
  padding: 33px
  cursor: pointer

.x-hellosign-embedded
  z-index: 10

.sign-success
  color: forestgreen

.sign-fail
  color: darkred


