.ico-back-arrow {
  width: 0.58em;
  height: 1em;
  fill: #273561;
}
.ico-check {
  width: 1.27em;
  height: 1em;
  fill: #FFF;
}
.ico-collapse-arrow {
  width: 0.8em;
  height: 1em;
  fill: #646C77;
}
.ico-logo-small {
  width: 1em;
  height: 1em;
  fill: #646C77;
}
.ico-next-arrow {
  width: 0.95em;
  height: 1em;
  fill: #FFF;
}
.ico-next-arrow2 {
  width: 0.89em;
  height: 1em;
  fill: #FFF;
}
.ico-question-circle {
  width: 1em;
  height: 1em;
  fill: #273561;
}
.ico-quote {
  width: 1.19em;
  height: 1em;
  fill: #62C87A;
}
.ico-right-arrow {
  width: 0.58em;
  height: 1em;
  fill: #273561;
}
.ico-select-arrow {
  width: 2em;
  height: 1em;
  fill: #646C77;
}
.ico-sh-email {
  width: 1.2em;
  height: 1em;
  fill: #646C77;
}
.ico-sh-id {
  width: 1.27em;
  height: 1em;
  fill: #646C77;
}
.ico-sh-name {
  width: 1.09em;
  height: 1em;
  fill: #646C77;
}
.ico-sh-person {
  width: 0.82em;
  height: 1em;
  fill: #646C77;
}
.ico-sh-phone {
  width: 1em;
  height: 1em;
  fill: #646C77;
}
.ico-signup-sucess {
  width: 1em;
  height: 1em;
  fill: #62C87A;
}
