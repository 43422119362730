.services
  h2
    margin-bottom: 32px

  .layout--slanted &__dedicated
    padding: 0 40px
    height: 555px

  &__fullwidth__wrapper
    display: flex

  &__onboard
    min-height: 700px
    margin-bottom: 100px

  &__onboard &__left
    position: absolute
    right: 50%

  &__onboard &__right
    padding: 130px 0 130px 50%
    background-color: $lightBg
    margin: auto 0
    padding-right: calc(50% - 585px)

  &__testimonial
    width: 770px
    margin: 0 auto

  &__powered
    font-size: 13px
    color: $fontColorGray
    margin-bottom: 32px
    img
      margin-left: 10px
      vertical-align: top

  &__tooltip
    position: absolute
    z-index: 2
    top: 0px
    left: calc(60% - 65px)
    background: white
    min-width: 130px
    box-shadow: 0 0 20px 0 rgba(0,0,0,0.08)
    border-radius: 3px
    font-size: 13px
    color: $fontColorGray
    pointer-events: none
    // cursor: pointer
    opacity: 1
    transition: opacity .3s $ease
    &--isaac
      left: 27%

    &-wrapper
      padding: 20px 10px 17px 18px
      position: relative
      &::after
        display: inline-block
        content: ' '
        position: absolute
        bottom: -9px
        left: 50%
        transform: translateX(-50%)
        +arr(20,10,#fff,b)
      span
        display: block
        margin-top: 4px
        font-size: 18px
        font-weight: 500
        color: $colorBlue
  &__small-list
    font-size: 12px
    padding-left: 0
    li
      padding-left: 18px
      background: transparent url('../images/icons/ico-check.svg') no-repeat 0 53%
    img
      margin-right: 10px

  &__tag
    background: #63719D
    border-radius: 3px
    color: white
    font-size: 10px
    font-weight: 500
    text-transform: uppercase
    padding: 5px 8px 5px
    margin-right: 10px
    min-width: 43px
    text-align: center
    width: fit-content
    margin-top: -29px

+r($tablet)
  .services__testimonial
    width: 100%

  .services
    &__onboard
      display: block
      background-color: $lightBg
      padding: 50px 0

    &__onboard &__left
      position: relative
      max-width: 83.33%
      margin: 0 8.33%
      right: auto
      img
        max-width: 100%

    &__onboard &__right
      position: relative
      padding: 0
      .layout__box
        padding: 0 40px

      // padding: 130px 0 130px 50%
      // background-color: $lightBg
      // margin: auto 0
      // padding-right: calc(50% - 585px)
