@keyframes contract
  0%
    opacity: 1
    transform-origin: 0 0
    // transform: scaleX(1)
  50%
    opacity: 0
  100%
    opacity: 0
    transform-origin: 0 0
    // transform: scaleX(0)

@keyframes expand
  0%
    opacity: 0
    transform-origin: 0 0
    // transform: scaleX(0)
  50%
    opacity: 0
  100%
    opacity: 1
    transform-origin: 0 0
    // transform: scaleX(1)

@keyframes contract-logomark
  0%
    opacity: 1
    // transform-origin: 0 0
    // transform: scaleX(1)
  50%
    opacity: 0
    // transform-origin: 0 0
    // transform: scaleX(1)
  100%
    opacity: 0
    // transform-origin: 0 0
    // transform: scaleX(1)

@keyframes expand-logomark
  0%
    opacity: 0
    // transform-origin: 0 0
    // transform: scaleX(1)
  50%
    opacity: 0
    // transform-origin: 0 0
    // transform: scaleX(1)
  100%
    opacity: 1
    // transform-origin: 0 0
    // transform: scaleX(1)

.header
  position: fixed
  left: 0
  right: 0
  top: 0
  z-index: 9
  background: white
  transition: box-shadow .25s $ease
  &__wrapper
    display: flex
    align-items: center
    padding: 26px 0
    transition: padding .25s $ease

  &__logolink
    font-size: 0
  &__logo
    opacity: 1
    position: absolute
    margin-top: -12px
    // animation: expand 0.5s 1 0s $ease;
  &__logomark
    opacity: 0
    position: absolute
    margin-top: -12px
    // animation: contract-logomark 0.8s 1 0s $ease;
  &__phone
    display: inline-block
    border-left: 1px solid #979797
    margin-left: 121px
    padding-left: 28px
    font-size: 13px
    color: $fontColorGray

    line-height: 1.45
    transition: margin-left .5s
    > span
      display: inline-block
      transform: translateY(1px)
    &-tel
      display: block
      color: $colorBlue
      font-weight: 500
      font-size: 14px
      transition: .25s $ease
      span
        font-weight: 400
        color: $fontColor
    &:hover
      .header__phone-tel
        color: $colorBlueHover

  &__menu
    margin: 0 0 0 auto
    padding: 0
    color: $fontColorGray
    font-size: 13px
    opacity: 1
    transition: opacity .25s $ease
    > li
      position: relative
      margin-right: 60px
      .ico-select-arrow
        font-size: 5px
        color: $fontColorGray
        transform: translateY(-1px)
        // transition: transform .25s $ease
        transition: .25s $ease
      &:last-child
        margin-right: 0
      > a:not(.btn)
        display: inline-block
        padding: 10px
        transition: color .25s $ease
        &.is-active, &:hover
          color: $colorBlue
        &.is-active
          font-weight: 500
      &:hover
        .ico-select-arrow
          color: $colorBlue
        .header__menu-second
          opacity: 1
          pointer-events: all
          transform: translate(0,0)
      .header__menu-second
        position: absolute
        // top: 61px
        top: 0
        left: -22px
        min-width: 200px
        opacity: 0
        pointer-events: none
        transform: translate(0,-10px)
        transition: .25s $ease
      ul
        background-color: #ffffff
        box-shadow: 0 9px 30px 0 rgba(0, 0, 0, 0.05)
        margin: 61px 0 0 auto
        padding: 10px 20px 10px 28px
        transition: .25s $ease
        li
          display: block
          margin: 3px 0
          > ul
            margin: 8px 0 0 5px
            box-shadow: none
            padding: 0 0 0 10px
            font-size: 12px
            line-height: 1.35
            border-left: 1px solid rgb(135, 144, 161)
            li
              margin-bottom: 0
              &:first-child a
                padding-top: 0
        a
          display: inline-block
          padding: 5px
          transition: color .25s $ease
          &.is-active, &:hover
            color: $colorBlue
          &.is-active
            font-weight: 500

  &__hamburger
    display: none
    margin-left: 25px
    span
      margin-left: 5px
      display: inline-block
      font-size: 10px
      padding: 5px
      border: 1px solid black

  &--logo-only
    .header
      position: absolute
      background: transparent
      &__phone, &__menu, &__hamburger
        opacity: 0
        pointer-events: none
    .mobile-navi
      position: absolute

.header--logo-only
    &__logo
      animation: expand 0.5s 1 0s $ease
    &__logomark
      margin-top: -12px
      animation: contract-logomark 0.8s 1 0s $ease

.is-scrolled:not(.header--logo-only)
  .header
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.05)
    // &__wrapper
      // padding: 15px 0
    &__menu
      > li
        ul
          margin-top: 61px
          li
            ul
              margin-top: 8px
    &__logo
      opacity: 0
      animation: contract 0.5s 1 0s $ease
    &__logomark
      opacity: 1
      animation: expand-logomark 0.5s 1 0s $ease
      width: 25px
    &__phone
      margin-left: 54px
      transition: margin-left .5s $ease
  .mobile-navi
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.05)

+r($desktop)
  .header
    &__menu
      /* hide all
      display: none
      // li
      //   display: none
      //   &:last-child
      //     display: block
    &__hamburger
      margin-left: auto
      display: block

+r($mobile)
  .header
    &__wrapper
      padding: 20px 0
    &__logo
      max-width: 90px
      .icon
        transform: scale(.8)
        transform-origin: left center
    &__phone
      margin-left: 112px
      padding-left: 18px
      // transform: translateY(2px)

    // &__menu
    //   display: none
    // &__hamburger
    //   margin-left: auto

  .is-scrolled:not(.header--logo-only)
    .header
      &__phone
        margin-left: 44px

+r(414)
  .header
    &__phone
      &-tel
        span
          display: none

/* MOBILE NAVI
.mobile-navi
  display: none
  position: fixed
  top: 0
  left: 0
  right: 0
  z-index: 8
  background: white
  opacity: 0
  pointer-events: none
  transform: translate(0,-100%)
  transition: .25s $ease
  &.is-active
    opacity: 1
    transform: translate(0,0)
    pointer-events: all
  &__wrapper
    padding: 105px 8px 30px 0
  &__menu
    color: $fontColorGray
    opacity: 1
    transition: opacity .25s $ease
    margin: 0 0 0 auto
    padding: 0
    li
      display: block
    > li
      font-size: 13px
      &:last-child
        font-size: 16px
      a
        &.is-active, &:hover
          color: $colorBlue
        &.is-active
          font-weight: 500
      > ul
        font-size: 16px
        margin: 6px 0 52px
        padding-left: 20px
        > li
          padding: 10px 0
          a
          > ul
            margin-top: 25px
            padding-left: 15px
            border-left: 1px solid rgb(135, 144, 161)
            > li
              font-size: 13px
              padding: 8px 0
              &:first-child
                padding-top: 0
  &__menu-old
    margin: 0 0 0 auto
    padding: 0
    color: $fontColorGray
    opacity: 1
    transition: opacity .25s $ease
    > li
      display: block
      margin-bottom: 20px
      font-size: 13px
      &:last-child
        margin-bottom: 0
      &:first-child
        a:not(.btn)
          padding-top: 0
      a:not(.btn)
        display: inline-block
        padding: 10px
        margin-left: -16px
        transition: color .25s $ease
        &.is-active, &:hover
          color: $colorBlue
        &.is-active
          font-weight: 500
      a.btn
        display: none
        font-size: 13px
        padding: 21px 40px 19px
        width: 100%
        max-width: 290px
      > ul
        margin: 0px 0 0 15px
        padding: 0
        > li
          display: block
          font-size: 16px
          a
            padding-top: 7px
            padding-bottom: 7px
          > ul li
            font-size: 13px
            display: block
            

+r($desktop)
  .mobile-navi
    display: block
    &__menu
      li
        a.btn
          display: block
+r($mobile)
  .mobile-navi
    // &__menu
    //   li
    //     a.btn
    //       display: block

+r($mobile-s)
  .mobile-navi
    &__menu
      > li a.btn
        // grow button
        display: block
        width: 100%
        max-width: 100%
