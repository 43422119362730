.bottom-cta
  h1
    color: $cabinBlue
    font-size: 34px
  position: relative
  background: #F6F7FB
  // border-top-left-radius: 100%
  // border-top-right-radius: 100%
  padding: 75px 0 50px 0
  margin-top: 0px
  // &::before
  //   display: inline-block
  //   content: ' '
  //   position: absolute
  //   z-index: 1
  //   top: -100px
  //   height: 300px
  //   left: -5%
  //   right: -5%
  //   background: #F6F7FB
  //   border-top-left-radius: 100%
  //   border-top-right-radius: 100%

  &__wrapper
    position: relative
    z-index: 2
    max-width: 570px
    margin-left: auto
    margin-right: auto
    text-align: center

  &__form
    margin-top: 50px


+r($mobile)
  .bottom-cta
    // margin-top: 220px
    padding-top: 70px
    padding-bottom: 15px
    &__wrapper
      text-align: left
    h1
      font-size: 25px
      margin-bottom: -.3em
    &::before
      left: -25%
      right: -25%
