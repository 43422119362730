.signup-email
  position: relative
  display: flex
  &.has-error
    input
      border-color: $colorRed
  input
    flex: 1 1 auto
    -webkit-appearance: none
    background: white
    height: auto
    border-radius: 3px
    border: 1px solid rgba($fontColorGray, .5)
    font-size: 14px
    line-height: 2
    box-shadow: none
    font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, Roboto, sans-serif
    // font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif
    // line-height: 100%
    // height: 50px
    // line-height: normal
    // line-height: 1.53
    // height: 64px
    // line-height: 64px
    color: $fontColorGray
    padding: 17px 15px 17px
    // padding: 0 15px
    transition: .25s $ease

    // direction: ltr
    // text-rendering: optimizeSpeed
    // letter-spacing: normal
    // word-spacing: normal
    // text-transform: none
    // text-indent: 0px
    // text-shadow: none
    // cursor: text
    &::placeholder
      // line-height: 2
      color: rgba($fontColorGray, .5)
    &:focus
      border-color: $colorBlue
  button
    flex: 0 0 auto
    margin-left: 8px

+r($mobile)
  .signup-email
    flex-direction: column
    input
      flex: 0 0 auto
    button
      flex: 0 0 auto
      margin-left: 0
      margin-top: 13px
      padding-top: 25px
      padding-bottom: 23px
