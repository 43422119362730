*
  box-sizing: border-box
  outline: none
  -webkit-overflow-scrolling: touch

a
  color: inherit
  text-decoration: none
  &:hover, &:focus
    text-decoration: none

strong, b
  font-weight: 500

ul
  list-style: none
  li
    display: inline-block

button, input, optgroup, select, textarea
  font-family: $defaultFamily

body, html
  background: white
  color: $fontColor
  font-family: $defaultFamily
  font-weight: normal
  font-size: 16px
  min-width: 320px
  max-width: 100%
  margin: 0
  overflow-x: hidden
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

::selection
  background: $colorBlue
  color: white

.page
  display: flex
  flex-direction: column
  min-height: 100vh
  &__content
    flex: 1 0 auto


.container
  width: 100%
  max-width: 1250px
  margin-left: auto
  margin-right: auto
  padding-left: 40px
  padding-right: 40px
  &--full
    max-width: 100%
  &--narrow
    max-width: 1050px
.responsive
  &__row
    padding-top: 80px
    padding-bottom: 80px

+r($tablet)
  .responsive
    &__row
      padding-top: 70px
      padding-bottom: 30px
      & > div
        padding-bottom: 20px

+r($mobile)
  .container
    padding-left: 20px
    padding-right: 20px

  .responsive
    padding-left: 20px !important
    padding-right: 20px !important
