#envelope-animation
  zoom: 1
+r(414)
  #envelope-animation
    zoom: 0.8

.paper
  animation: paper-slide 1.2s ease-out
  animation-fill-mode: forwards

.checkmark-outer
  animation: checkmark-outer 1s cubic-bezier(0.85, 0, 0.15, 1), checkmark-up 1.2s ease-out
  animation-fill-mode: forwards
  stroke-dasharray: 45
  stroke: #273561

.checkmark-icon
  animation: checkmark 0.8s cubic-bezier(0.76, 0, 0.24, 1) 0.8s, checkmark-up 1s ease-out
  animation-fill-mode: forwards
  stroke-dasharray: 40
  stroke-dashoffset: 40

.confetti
  animation: confetti 1.2s ease-out
  animation-fill-mode: forwards
  animation-delay: 1.2s
  transform-origin: center

.pace-running *, .pace-running *:before, .pace-running *:after
  animation-play-state: paused !important

@keyframes checkmark-up
  0%
    transform: translateY(15px)
  100%
    transform: translateY(0px)

@keyframes checkmark
  0%
    stroke: #fff
  20%
    stroke: #273561

  100%
    stroke: #273561
    stroke-dashoffset: 0

@keyframes confetti
  0%
    opacity: 0
    transform: scale(1) translateY(15px)
  50%
    opacity: 1
  100%
    opacity: 0
    transform: scale(1.3) translateY(-10px)

@keyframes checkmark-outer
  0%
    stroke-dashoffset: 171px
    stroke-dasharray: 171px
  100%
    stroke-dashoffset: 5px
    stroke-dasharray: 171px
@keyframes paper-slide
  0%
    d: path("M78 178H266V192.5L192 240L178.59 231.466C173.349 228.131 166.651 228.131 161.41 231.466L148 240L78 193V178Z")
  100%
    d: path("M78 128H266V192.5L192 240L178.59 231.466C173.349 228.131 166.651 228.131 161.41 231.466L148 240L78 193V128Z")


