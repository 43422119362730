.title-section 
  margin: 120px 0
  &__wrapper 
    text-align: center
    max-width: 580px
    margin-left: auto
    margin-right: auto 
  strong, b 
    color: $cabinBlue  

+r($desktop) 
  .title-section 
    margin: 100px 0  


+r($tablet) 
  .title-section 
    margin: 0
    padding: 55px 0 40px
    background: #F6F7FB
    &__wrapper 
      margin-left: 0
      text-align: left   

/* Learn more */
.learn-more 
  font-size: 12px
  font-weight: 500
  color: $colorBlue
  cursor: pointer
  transition: color .25s $ease
  .ico 
    font-size: 11px
    margin-left: 5px
    transform: translate(0, 1px)
    transition: transform .25s $ease 
  &:hover 
    // color: $colorGreen
    color: $colorBlueHover
    .ico 
         // transform: translate(3px, 1px)

/* make all tooltip icons blue with important */
.ico-question-circle 
  fill: $colorBlue !important 
