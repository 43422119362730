$animationDuration: 12s

@keyframes cube1
  0%
    opacity: 1
  5.56%
    opacity: 0
  66.7%
    opacity: 0
  72.2%
    opacity: 1
  100%
    opacity: 1

@keyframes text1
  0%
    opacity: 0
  70.0%
    margin-left: 10px
    opacity: 0
  76.7%
    margin-left: 0px
    opacity: 1
  99.9%
    opacity: 1
  100%
    opacity: 0

@keyframes cube2
  0%
    opacity: 0
  5.56%
    opacity: 1
  33.3%
    opacity: 1
  38.9%
    opacity: 0
  100%
    opacity: 0

@keyframes text2
  0%
    opacity: 0
  3.33%
    opacity: 0
    margin-left: 10px
  10%
    margin-left: 0
    opacity: 1
  33.3%
    opacity: 1
  33.4%
    opacity: 0
  100%
    opacity: 0

@keyframes cube3
  0%
    opacity: 0
  33.3%
    opacity: 0
  38.9%
    opacity: 1
  66.7%
    opacity: 1
  72.2%
    opacity: 0
  100%
    opacity: 0

@keyframes text3
  0%
    opacity: 0
  36.7%
    margin-left: 10px
    opacity: 0
  43.3%
    margin-left: 0
    opacity: 1
  66.6%
    opacity: 1
  66.7%
    opacity: 0
  100%
    opacity: 0

.cubes
  position: relative
  height: 470px
  width: 510px

  margin: auto
  .cube
    position: absolute
    width: 100%
    padding-right: 110px
    animation-duration: $animationDuration
    animation-iteration-count: infinite
    &__text
      opacity: 0
      background-color: #ffffff
      border-radius: 3px
      padding: 13px 18px
      position: absolute
      font-weight: 600
      font-size: 12px
      color: $cabinBlue
      z-index: 1
      box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05)
      animation-duration: $animationDuration
      animation-iteration-count: infinite
    &__text11
      animation-name: text1
      top: 187px
      left: 110px
    &__text12
      animation-name: text1
      top: 150px
      left: 352px
    &__text13
      animation-name: text1
      top: 328px
      left: 291px
    &__text21
      animation-name: text2
      top: 155px
      left: 48px
    &__text22
      animation-name: text2
      top: 186px
      left: 290px
    &__text23
      animation-name: text2
      top: 365px
      left: 230px
    &__text31
      animation-name: text3
      top: 300px
      left: 49px
    &__text32
      animation-name: text3
      top: 225px
      left: 346px
    &__text33
      animation-name: text3
      top: 183px
      left: 170px

  .cube1
    animation-name: cube1

  .cube2
    animation-name: cube2

  .cube3
    animation-name: cube3
  &-stop *
    animation-play-state: paused

+r($mobile-s)
  .cubes
    position: relative
    height: 320px
    width: 410px

    margin: auto

    &__text
      padding: 11px 16px
    .cube
      &__text
        padding: 10px 14px
        font-weight: 500
        font-size: 10px
      &__text11
        top: 138px
        left: 80px
      &__text12
        top: 112px
        left: 264px
      &__text13
        top: 246px
        left: 218px
      &__text21
        top: 113px
        left: 35px
      &__text22
        top: 138px
        left: 218px
      &__text23
        top: 274px
        left: 172px
      &__text31
        top: 220px
        left: 35px
      &__text32
        top: 165px
        left: 267px
      &__text33
        top: 167px
        left: 128px

+r(375)
  .cubes
    transform: translateX(-20px) scale(0.9)
