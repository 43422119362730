.tippy-touch
  cursor: pointer !important

.tippy-notransition
  transition: none !important

.tippy-popper
  max-width: 400px
  -webkit-perspective: 800px
  perspective: 800px
  z-index: 9999
  outline: 0
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1)
  pointer-events: none
  &.html-template
    max-width: 96%
    max-width: calc(100% - 20px)
  &[x-placement^=top]
    [x-arrow]
      border-top: 7px solid #FFFFFF
      border-right: 7px solid transparent
      border-left: 7px solid transparent
      bottom: -7px
      margin: 0 9px
      &.arrow-small
        border-top: 5px solid #FFFFFF
        border-right: 5px solid transparent
        border-left: 5px solid transparent
        bottom: -5px
      &.arrow-big
        border-top: 10px solid #FFFFFF
        border-right: 10px solid transparent
        border-left: 10px solid transparent
        bottom: -10px
    [x-circle]
      -webkit-transform-origin: 0 33%
      transform-origin: 0 33%
      &.enter
        -webkit-transform: scale(1) translate(-50%, -55%)
        transform: scale(1) translate(-50%, -55%)
        opacity: 1
      &.leave
        -webkit-transform: scale(0.15) translate(-50%, -50%)
        transform: scale(0.15) translate(-50%, -50%)
        opacity: 0
    .tippy-tooltip
      &.light-theme
        [x-circle]
          background-color: #fff
        [x-arrow]
          border-top: 7px solid #fff
          border-right: 7px solid transparent
          border-left: 7px solid transparent
          &.arrow-small
            border-top: 5px solid #fff
            border-right: 5px solid transparent
            border-left: 5px solid transparent
          &.arrow-big
            border-top: 10px solid #fff
            border-right: 10px solid transparent
            border-left: 10px solid transparent
      &.transparent-theme
        [x-circle]
          background-color: rgba(0, 0, 0, 0.7)
        [x-arrow]
          border-top: 7px solid rgba(0, 0, 0, 0.7)
          border-right: 7px solid transparent
          border-left: 7px solid transparent
          &.arrow-small
            border-top: 5px solid rgba(0, 0, 0, 0.7)
            border-right: 5px solid transparent
            border-left: 5px solid transparent
          &.arrow-big
            border-top: 10px solid rgba(0, 0, 0, 0.7)
            border-right: 10px solid transparent
            border-left: 10px solid transparent
    [data-animation=perspective]
      -webkit-transform-origin: bottom
      transform-origin: bottom
      &.enter
        opacity: 1
        -webkit-transform: translateY(-10px) rotateX(0)
        transform: translateY(-10px) rotateX(0)
      &.leave
        opacity: 0
        -webkit-transform: translateY(0) rotateX(90deg)
        transform: translateY(0) rotateX(90deg)
    [data-animation=fade]
      &.enter
        opacity: 1
        -webkit-transform: translateY(-10px)
        transform: translateY(-10px)
      &.leave
        opacity: 0
        -webkit-transform: translateY(-10px)
        transform: translateY(-10px)
    [data-animation=shift]
      &.enter
        opacity: 1
        -webkit-transform: translateY(-10px)
        transform: translateY(-10px)
      &.leave
        opacity: 0
        -webkit-transform: translateY(0)
        transform: translateY(0)
    [data-animation=scale]
      &.enter
        opacity: 1
        -webkit-transform: translateY(-10px) scale(1)
        transform: translateY(-10px) scale(1)
      &.leave
        opacity: 0
        -webkit-transform: translateY(0) scale(0)
        transform: translateY(0) scale(0)
  &[x-placement^=bottom]
    [x-arrow]
      border-bottom: 7px solid #FFFFFF
      border-right: 7px solid transparent
      border-left: 7px solid transparent
      top: -7px
      margin: 0 9px
      &.arrow-small
        border-bottom: 5px solid #FFFFFF
        border-right: 5px solid transparent
        border-left: 5px solid transparent
        top: -5px
      &.arrow-big
        border-bottom: 10px solid #FFFFFF
        border-right: 10px solid transparent
        border-left: 10px solid transparent
        top: -10px
    [x-circle]
      -webkit-transform-origin: 0 -50%
      transform-origin: 0 -50%
      &.enter
        -webkit-transform: scale(1) translate(-50%, -45%)
        transform: scale(1) translate(-50%, -45%)
        opacity: 1
      &.leave
        -webkit-transform: scale(0.15) translate(-50%, -5%)
        transform: scale(0.15) translate(-50%, -5%)
        opacity: 0
    .tippy-tooltip
      &.light-theme
        [x-circle]
          background-color: #fff
        [x-arrow]
          border-bottom: 7px solid #fff
          border-right: 7px solid transparent
          border-left: 7px solid transparent
          &.arrow-small
            border-bottom: 5px solid #fff
            border-right: 5px solid transparent
            border-left: 5px solid transparent
          &.arrow-big
            border-bottom: 10px solid #fff
            border-right: 10px solid transparent
            border-left: 10px solid transparent
      &.transparent-theme
        [x-circle]
          background-color: rgba(0, 0, 0, 0.7)
        [x-arrow]
          border-bottom: 7px solid rgba(0, 0, 0, 0.7)
          border-right: 7px solid transparent
          border-left: 7px solid transparent
          &.arrow-small
            border-bottom: 5px solid rgba(0, 0, 0, 0.7)
            border-right: 5px solid transparent
            border-left: 5px solid transparent
          &.arrow-big
            border-bottom: 10px solid rgba(0, 0, 0, 0.7)
            border-right: 10px solid transparent
            border-left: 10px solid transparent
    [data-animation=perspective]
      -webkit-transform-origin: top
      transform-origin: top
      &.enter
        opacity: 1
        -webkit-transform: translateY(10px) rotateX(0)
        transform: translateY(10px) rotateX(0)
      &.leave
        opacity: 0
        -webkit-transform: translateY(0) rotateX(-90deg)
        transform: translateY(0) rotateX(-90deg)
    [data-animation=fade]
      &.enter
        opacity: 1
        -webkit-transform: translateY(10px)
        transform: translateY(10px)
      &.leave
        opacity: 0
        -webkit-transform: translateY(10px)
        transform: translateY(10px)
    [data-animation=shift]
      &.enter
        opacity: 1
        -webkit-transform: translateY(10px)
        transform: translateY(10px)
      &.leave
        opacity: 0
        -webkit-transform: translateY(0)
        transform: translateY(0)
    [data-animation=scale]
      &.enter
        opacity: 1
        -webkit-transform: translateY(10px) scale(1)
        transform: translateY(10px) scale(1)
      &.leave
        opacity: 0
        -webkit-transform: translateY(0) scale(0)
        transform: translateY(0) scale(0)
  &[x-placement^=left]
    [x-arrow]
      border-left: 7px solid #FFFFFF
      border-top: 7px solid transparent
      border-bottom: 7px solid transparent
      right: -7px
      margin: 6px 0
      &.arrow-small
        border-left: 5px solid #FFFFFF
        border-top: 5px solid transparent
        border-bottom: 5px solid transparent
        right: -5px
      &.arrow-big
        border-left: 10px solid #FFFFFF
        border-top: 10px solid transparent
        border-bottom: 10px solid transparent
        right: -10px
    [x-circle]
      -webkit-transform-origin: 50% 0
      transform-origin: 50% 0
      &.enter
        -webkit-transform: scale(1) translate(-50%, -50%)
        transform: scale(1) translate(-50%, -50%)
        opacity: 1
      &.leave
        -webkit-transform: scale(0.15) translate(-50%, -50%)
        transform: scale(0.15) translate(-50%, -50%)
        opacity: 0
    .tippy-tooltip
      &.light-theme
        [x-circle]
          background-color: #fff
        [x-arrow]
          border-left: 7px solid #fff
          border-top: 7px solid transparent
          border-bottom: 7px solid transparent
          &.arrow-small
            border-left: 5px solid #fff
            border-top: 5px solid transparent
            border-bottom: 5px solid transparent
          &.arrow-big
            border-left: 10px solid #fff
            border-top: 10px solid transparent
            border-bottom: 10px solid transparent
      &.transparent-theme
        [x-circle]
          background-color: rgba(0, 0, 0, 0.7)
        [x-arrow]
          border-left: 7px solid rgba(0, 0, 0, 0.7)
          border-top: 7px solid transparent
          border-bottom: 7px solid transparent
          &.arrow-small
            border-left: 5px solid rgba(0, 0, 0, 0.7)
            border-top: 5px solid transparent
            border-bottom: 5px solid transparent
          &.arrow-big
            border-left: 10px solid rgba(0, 0, 0, 0.7)
            border-top: 10px solid transparent
            border-bottom: 10px solid transparent
    [data-animation=perspective]
      -webkit-transform-origin: right
      transform-origin: right
      &.enter
        opacity: 1
        -webkit-transform: translateX(-10px) rotateY(0)
        transform: translateX(-10px) rotateY(0)
      &.leave
        opacity: 0
        -webkit-transform: translateX(0) rotateY(-90deg)
        transform: translateX(0) rotateY(-90deg)
    [data-animation=fade]
      &.enter
        opacity: 1
        -webkit-transform: translateX(-10px)
        transform: translateX(-10px)
      &.leave
        opacity: 0
        -webkit-transform: translateX(-10px)
        transform: translateX(-10px)
    [data-animation=shift]
      &.enter
        opacity: 1
        -webkit-transform: translateX(-10px)
        transform: translateX(-10px)
      &.leave
        opacity: 0
        -webkit-transform: translateX(0)
        transform: translateX(0)
    [data-animation=scale]
      &.enter
        opacity: 1
        -webkit-transform: translateX(-10px) scale(1)
        transform: translateX(-10px) scale(1)
      &.leave
        opacity: 0
        -webkit-transform: translateX(0) scale(0)
        transform: translateX(0) scale(0)
  &[x-placement^=right]
    [x-arrow]
      border-right: 7px solid #FFFFFF
      border-top: 7px solid transparent
      border-bottom: 7px solid transparent
      left: -7px
      margin: 6px 0
      &.arrow-small
        border-right: 5px solid #FFFFFF
        border-top: 5px solid transparent
        border-bottom: 5px solid transparent
        left: -5px
      &.arrow-big
        border-right: 10px solid #FFFFFF
        border-top: 10px solid transparent
        border-bottom: 10px solid transparent
        left: -10px
    [x-circle]
      -webkit-transform-origin: -50% 0
      transform-origin: -50% 0
      &.enter
        -webkit-transform: scale(1) translate(-50%, -50%)
        transform: scale(1) translate(-50%, -50%)
        opacity: 1
      &.leave
        -webkit-transform: scale(0.15) translate(-50%, -50%)
        transform: scale(0.15) translate(-50%, -50%)
        opacity: 0
    .tippy-tooltip
      &.light-theme
        [x-circle]
          background-color: #fff
        [x-arrow]
          border-right: 7px solid #fff
          border-top: 7px solid transparent
          border-bottom: 7px solid transparent
          &.arrow-small
            border-right: 5px solid #fff
            border-top: 5px solid transparent
            border-bottom: 5px solid transparent
          &.arrow-big
            border-right: 10px solid #fff
            border-top: 10px solid transparent
            border-bottom: 10px solid transparent
      &.transparent-theme
        [x-circle]
          background-color: rgba(0, 0, 0, 0.7)
        [x-arrow]
          border-right: 7px solid rgba(0, 0, 0, 0.7)
          border-top: 7px solid transparent
          border-bottom: 7px solid transparent
          &.arrow-small
            border-right: 5px solid rgba(0, 0, 0, 0.7)
            border-top: 5px solid transparent
            border-bottom: 5px solid transparent
          &.arrow-big
            border-right: 10px solid rgba(0, 0, 0, 0.7)
            border-top: 10px solid transparent
            border-bottom: 10px solid transparent
    [data-animation=perspective]
      -webkit-transform-origin: left
      transform-origin: left
      &.enter
        opacity: 1
        -webkit-transform: translateX(10px) rotateY(0)
        transform: translateX(10px) rotateY(0)
      &.leave
        opacity: 0
        -webkit-transform: translateX(0) rotateY(90deg)
        transform: translateX(0) rotateY(90deg)
    [data-animation=fade]
      &.enter
        opacity: 1
        -webkit-transform: translateX(10px)
        transform: translateX(10px)
      &.leave
        opacity: 0
        -webkit-transform: translateX(10px)
        transform: translateX(10px)
    [data-animation=shift]
      &.enter
        opacity: 1
        -webkit-transform: translateX(10px)
        transform: translateX(10px)
      &.leave
        opacity: 0
        -webkit-transform: translateX(0)
        transform: translateX(0)
    [data-animation=scale]
      &.enter
        opacity: 1
        -webkit-transform: translateX(10px) scale(1)
        transform: translateX(10px) scale(1)
      &.leave
        opacity: 0
        -webkit-transform: translateX(0) scale(0)
        transform: translateX(0) scale(0)
  .tippy-tooltip
    &.transparent-theme
      background-color: rgba(0, 0, 0, 0.7)
      &[data-animatefill]
        background-color: transparent
    &.light-theme
      color: #26323d
      box-shadow: 0 4px 20px 4px rgba(0, 20, 60, 0.1), 0 4px 80px -8px rgba(0, 20, 60, 0.2)
      background-color: #fff
      &[data-animatefill]
        background-color: transparent

.tippy-tooltip
  position: relative
  color: $fontColorGray
  border-radius: 4px
  font-size: 12px
  line-height: 1.58
  padding: 17px 27px
  text-align: center
  will-change: transform
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  background-color: #FFFFFF
  box-shadow: 0 0 20px 0 rgba(0,0,0,0.08)
  border-radius: 3px

.tippy-tooltip--small
  padding: .25rem .5rem
  font-size: .8rem

.tippy-tooltip--big
  padding: .6rem 1.2rem
  font-size: 1.2rem

.tippy-tooltip
  &[data-animatefill]
    overflow: hidden
    background-color: transparent
  &[data-interactive]
    pointer-events: auto
  &[data-inertia]
    transition-timing-function: cubic-bezier(0.53, 2, 0.36, 0.85)
  [x-arrow]
    position: absolute
    width: 0
    height: 0
  [x-circle]
    position: absolute
    will-change: transform
    background-color: #FFFFFF
    border-radius: 50%
    width: 130%
    width: calc(110% + 2rem)
    left: 50%
    top: 50%
    z-index: -1
    overflow: hidden
    transition: all ease
    &:before
      content: ""
      padding-top: 90%
      float: left

@media (max-width: 450px)
  .tippy-popper
    max-width: 96%
    max-width: calc(100% - 20px)
