.circle-1,.text-1
  animation: tickfade 1s forwards
  animation-delay: 0.6s

.circle-2,.text-2
  animation: tickfade 1s forwards
  animation-delay: 1.2s

.circle-3, .text-3
  animation: tickfade 1s forwards
  animation-delay: 1.8s

.circle-4, .text-4
  animation: tickfade 1s forwards
  animation-delay: 2.4s

.circle-5, .text-5
  animation: tickfade 1s forwards
  animation-delay: 3s

@keyframes tickfade
  0%
    opacity: 0.15
  100%
    opacity: 1
