h1,h2,h3,h4
  font-weight: 500
  margin: .75em 0
  line-height: 1.25

h1
  font-size: 40px
  line-height: 1.25
  margin: .5em 0
  letter-spacing: -0.005em

h2
  color: $cabinBlue
  font-size: 30px
  line-height: 1.4
  letter-spacing: -1px

h3
  font-size: 18px
  line-height: 1.5

.t-small
  font-size: 13px
  color: $fontColorGray

.t-paragraph
  font-size: 16px
  color: $fontColorGray
  line-height: 1.875
  span
    color: $fontColor
.symbol
  font-family: "SF Pro Text", Arial, -apple-system, BlinkMacSystemFont, sans-serif
  line-height: 1em
.br
  display: block
  clear: both
  content: " "

.-br
  display: inline
    clear: none
  content: " "

+r($wide)
  .br.xl
    display: inline
    clear: none
  .-br.xl
    display: block
    clear: both
+r($desktop)
  .br.lg
    display: inline
    clear: none
  .-br.lg
    display: block
    clear: both
+r($tablet)
  .br.md
    display: inline
    clear: none
  .-br.md
    display: block
    clear: both
+r($mobile)
  .br.sm
    display: inline
    clear: none
  .-br.sm
    display: block
    clear: both
+r($mobile-s)
  .br.xs
    display: inline
    clear: none
  .-br.xs
    display: block
    clear: both
+r(512)
  #services-secretary
    .br.xs
      display: inline
      clear: none

/* for aos scroll transforms */
span.t-small, span.t-paragraph
  display: inline-block

.t-center
  text-align: center

.t-uppercase
  text-transform: uppercase

.t-bold
  font-weight: 700

+r($tablet)
  h1
     // font-size: 35px

  h2
    font-size: 22px

+r($mobile)
  h1
    font-size: 32px

+r(375)
  h1
    font-size: 30px
