/* PRICING
.p-builder
  margin: 120px 0 150px 0
  display: none
  &.is-visible
    display: block

  /* in first section - show only first
  &[data-index="1"]
    .p-builder-box
      display: none
      /* first child is a header
      /* need tweking if html will change
      &:nth-child(2)
        display: block
      &.is-choosen, &[data-pagename="monthly"]
        + .p-builder-box
          display: block


  &__header
    h2
      margin-top: 0.25em
    &-desc
      display: flex
      align-items: center
      .t-paragraph
        margin: 0
        margin-right: 10px
      .ico
        font-size: 17px
        color: $colorBlue

+r($desktop)
  .p-builder
    margin: 100px 0 120px 0

+r($tablet)
  .p-builder
    margin: 50px 0 70px 0

  .pricing .p-builder
    &:first-child
      margin-top: 0

/* Builder box
.p-builder-box
  position: relative
  margin: 50px 0px 80px
  background: #FFFFFF
  box-shadow: 0 0 30px 0 rgba(0,0,0,0.05)
  border-radius: 3px
  &.is-choosen
    // is for the active addon styles
    .p-builder-box
      &__head-icon
        .icon
          filter: saturate(1)
      &__addon
        background: $colorBlue
      &__toggle
        background-color: rgba($colorBlue, .2)
        &::after
          left: calc(100% - 15px)
          background-color: $colorBlue

  &__wrapper
    position: relative
    overflow: hidden
    z-index: 2
    padding: 35px 65px 10px
  &__head
    display: flex
    align-items: center
    &-icon
      margin-left: 20px
      .icon
        filter: saturate(0)
        transition: .25s $ease

  &__toggle
    position: relative
    background: rgba($fontColorGray, .1)
    border-radius: 8px
    width: 40px
    height: 15px
    margin-right: 20px
    cursor: pointer
    transition: background .25s $ease
    &::after
      display: inline-block
      content: ' '
      position: absolute
      left: -5px
      top: 50%
      width: 22px
      height: 22px
      border-radius: 50%
      background: $fontColorGray
      z-index: 1
      transform: translateY(-50%)
      box-shadow: 4px 0 8px 0 rgba(0,0,0,0.11)
      transition: .25s $ease


  &__addon
    position: absolute
    left: 50px
    top: -12px
    color: white
    font-weight: 500
    line-height: 1
    font-size: 10px
    letter-spacing: 0.071em
    padding: 9px 12px 7px
    background: #9A9FB5
    border-radius: 3px
    transition: background .25s $ease

  &__tag
    position: absolute
    top: 45px
    right: -45px
    transform: rotate(45deg)
    font-size: 10px
    color: white
    font-weight: 500
    text-transform: uppercase
    letter-spacing: 0.071em
    text-align: center
    background: $colorBlue
    padding: 10px 60px 8px
    pointer-events: none

  &__name
    font-size: 14px
    font-weight: 500
    transform: translateY(2px)
    color: $fontColorGray

  &__price
    flex: 0 0 200px
    white-space: nowrap
    margin-left: auto
    // text-align: right
    line-height: 1.42
    &-top
      display: block
      font-size: 10px
      color: $fontColorGray
    &-core
      font-size: 16px
      color: $fontColor
    &-for
      display: inline-block
      margin-left: 3px
      font-size: 14px
      color: $fontColorGray
  &__list
    display: flex
    margin: 40px -15px
    &-col
      flex: 0 1 50%
      max-width: 50%
      min-width: 1px
      min-height: 0
      padding: 0 15px
    &-el
      // margin-bottom: 10px
      display: flex
      span
        display: inline-block
        margin-left: 10px
        font-size: 12px
        line-height: 1.83
        color: $fontColorGray
      .ico
        flex: 0 0 auto
        color: $colorGreen
        font-size: 8px
        transform: translateY(.5em)
      .ico-question-circle
        font-size: 12px
        margin-left: 5px
        vertical-align: middle
        transform: translateY(-1px)

  &__help-text
    // position: absolute
    // top: 0
    // left: 0
    // right: 0
    //
    font-size: 10px
    color: $fontColorGray
    line-height: 2.2
    max-width: 560px
    // margin: 20px auto
    margin: -5px auto 25px
    text-align: center
    .ico-question-circle
      margin-left: 5px
      font-size: 12px
      vertical-align: middle
      transform: translateY(-1px)



  &__options
    display: none
    position: relative
    z-index: 1
    background: #F6F7FB
    border-radius: 3px
    padding: 40px 65px 30px
    margin-top: -10px
    &.is-required
      .p-builder-option
        animation: optionAttentionRequired 2s ease
        animation-iteration-count: infinite
        &__wrapper
          animation: optionAttentionRequiredWrapper 2s ease
          animation-iteration-count: infinite
    &.have-selected
      display: block
    &.always-visible
      display: block

    &-list
      display: flex
      flex-wrap: wrap
      margin-left: -10px
      margin-right: -10px
      &[data-number-of-elements="5"]
        .p-builder-option
          flex-basis: 20%
          max-width: 20%
      &[data-number-of-elements="4"]
        .p-builder-option
          flex-basis: 25%
          max-width: 25%
      &[data-number-of-elements="3"]
        .p-builder-option
          flex-basis: 33.3%
          max-width: 33.3%
      &[data-number-of-elements="2"]
        .p-builder-option
          flex-basis: 50%
          max-width: 50%
      &[data-number-of-elements="1"]
        .p-builder-option
          flex-basis: 100%
          max-width: 100%


  &-slider
    max-width: 800px
    margin-left: auto
    margin-right: auto
    &__val
      margin: 25px 0
      font-size: 18px
      color: $fontColor
      text-align: center
      opacity: 0
      transition: .25s $ease
      &.is-visible
        opacity: 1
    &__price
      display: flex
      align-items: center
      justify-content: center
      opacity: 0
      transition: .25s $ease
      &.is-visible
        opacity: 1
      &-box
        padding: 15px 25px 11px
        text-align: center
        background: white
      span
        display: inline-block
        &:first-child
          display: block
          font-size: 16px
          color: $fontColor
        &:last-child
          font-size: 14px
          margin-top: 5px
          color: $fontColorGray

+r($desktop)
  .p-builder-box
    &__wrapper
      padding-left: 40px
      padding-right: 40px

    &__options
      padding-left: 30px
      padding-right: 30px


+r($tablet)
  .p-builder-box
    margin: 40px 0 70px
    &__wrapper
      padding-left: 25px
      padding-right: 25px

    &__options
      padding: 18px 10px
      &-list
        display: block
        margin: 0

    &__list
      display: block
      margin: 35px 0
      &-col
        max-width: 100%
        padding: 0

    &__price
      flex-basis: 150px

    &-slider
      padding-left: 15px
      padding-right: 15px
      margin-top: 15px

    &__help-text
      margin-top: 10px

+r($mobile)
  .p-builder-box
    &__head
      flex-wrap: wrap
    &__name
      // flex-grow: 1
      max-width: calc(100% - 40px - 50px - 13px)
    &__price
      flex-basis: 100%
      margin-top: 25px


/* BUILDER OPTION
.p-builder-option
  flex: 0 1 auto
  padding: 10px
  &__wrapper
    background: #FFFFFF
    box-shadow: 0 0 30px 0 rgba(0,0,0,0.05)
    border-radius: 3px
    opacity: .5
    cursor: pointer
    transition: .25s $ease
  &__head
    display: flex
    align-items: center
    justify-content: center
    border-bottom: 4px solid $fontColorGray
    min-height: 65px
    padding: 10px
    text-align: center
  &__name
    font-size: 12px
    color: $fontColorGray
    font-weight: normal
    line-height: 1.66
    span
      display: block

  &__price
    padding: 25px 10px
    text-align: center
    span
      display: inline-block
      &:first-child
        display: block
        font-size: 18px
        color: $fontColor
      &:last-child
        font-size: 14px
        margin-top: 5px

/* disable interactions if it's animating
.p-builder-box__options:not(.is-required)
  .p-builder-option__wrapper
    &:hover
      opacity: .7
    &.is-selected
      opacity: 1
      box-shadow: 0 0 20px 0 rgba(0,0,0,0.15)
      .p-builder-option__head
        border-color: $colorBlue


@keyframes optionAttentionRequired
  // from
  //   transform: scale3d(1, 1, 1)
  //
  // 10%,
  // 20%
  //   transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg)
  //
  // 30%,
  // 50%,
  // 70%,
  // 90%
  //   transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg)
  //
  // 40%,
  // 60%,
  // 80%
  //   transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg)
  //
  // to
  //   transform: scale3d(1, 1, 1)

  from
    transform: scale3d(1, 1, 1)
  50%
    transform: scale3d(1.04, 1.04, 1.04)
  to
    transform: scale3d(1, 1, 1)

@keyframes optionAttentionRequiredWrapper
  from
    opacity: .7
  50%
    opacity: .9
  to
    opacity: .7


+r($tablet)
  .p-builder-option
    /* to reset data-number-of-elements
    max-width: 100% !important
    padding: 0
    margin-bottom: 10px
    &:last-child
      margin-bottom: 0
    &__wrapper
      position: relative
      display: flex
      align-items: center
      padding: 12px 15px 12px 20px
      opacity: 1
      &:hover
        opacity: 1
      &::before, &::after
        display: block
        content: ' '
        position: absolute
        left: 20px
        top: 50%
        transform: translateY(-50%)
        border-radius: 50%
      &::before
        border: 1px solid rgba($fontColorGray, .5)
        width: 20px
        height: 20px

      &::after
        width: 12px
        height: 12px
        left: 25px
        background: $colorBlue
        transform: translateY(-50%) scale(0)
        transition: transform .25s $ease

      &.is-selected
        box-shadow: 0 0 30px 0 rgba(0,0,0,0.05)
        &::before
        &::after
          transform: translateY(-50%) scale(1)

    &__head
      border-bottom: 0
      // offset for radio btn
      padding: 0 20px 0 45px
      min-height: 36px

    &__name
      color: $fontColorGray
      text-align: left
      span
        display: block

    &__price
      flex: 0 0 auto
      display: flex
      align-items: center
      padding: 0
      margin-left: auto
      span
        font-size: 12px
        font-weight: 500
        &:last-child
          margin-top: 0
          margin-left: 5px
