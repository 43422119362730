.testimonial 
  background: #FFFFFF
  padding: 22px 30px 30px 22px
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05)
  border-radius: 3px
  margin-bottom: 25px
  margin-top: 25px
  &__wrapper 
    display: flex 
  &__multiple 
    margin-bottom: 30px 
  &__icon 
    flex: 0 0 80px
    font-size: 27px
    color: $cabinBlue 
  &__content 
    p 
      font-size: 16px
      color: $fontColorGray
      margin: 0
      line-height: 30px
      strong 
        font-weight: 500
        color: $cabinBlue 
      & + p 
        margin-top: 1em   
  &__author 
    display: flex
    align-items: center
    margin-top: 30px
    &-avatar 
      flex: 0 0 60px
      position: relative
      z-index: 1
      width: 60px
      height: 60px
      border-radius: 50%
      overflow: hidden
      background: #FFFFFF
      font-size: 0
      box-shadow: 0 0 8px 0 rgba(0,0,0,0.14)
      img 
        position: absolute
        top: 50%
        left: 50%
        width: 102%
        transform: translate(-50%,-50%)  

    &-text 
      flex: 0 1 auto
      margin-left: 18px
      font-size: 12px
      color: $fontColorGray
      span 
        color: $cabinBlue
        font-weight: 600   
  .swiper-container-horizontal 
    .swiper-pagination-bullets 
      .swiper-pagination-bullet 
        width: 6px
        height: 6px
        margin: 0 2px
        background-color: #9A9FB5
        opacity: 1 
      .swiper-pagination-bullet-active 
        background-color: $cabinBlue    

.testimonial__small .testimonial 
  padding: 22px
  &__icon 
    flex: 0 0 40px
    font-size: 20px 
  &__content 
    p 
      font-size: 13px
      line-height: 20px  
  &__author 
    margin-top: 24px
    &-avatar 
      flex: 0 0 48px
      position: relative
      z-index: 1
      width: 48px
      height: 48px   

+r($mobile) 
  .testimonial 
    padding-left: 15px
    &__icon 
      flex-basis: 50px 
    &__author 
      margin-top: 40px
      margin-left: -40px   
