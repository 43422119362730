.table-collapse
  // top level componenet for animated collapse
  margin-bottom: 30px

.sh-table
  // margin-top: 60px
  padding: 40px 0
  &__title
    font-size: 12px
    font-weight: 500
    color: $fontColorGray
    line-height: 1.88
    margin: 15px 0
    .ico
      margin-left: 5px
      vertical-align: middle
      transform: translateY(-1px)
  &__wrapper
    overflow-x: scroll
    padding-bottom: 20px

  &__info
    margin-top: 20px
    font-size: 11px
    color: $fontColorGray
    line-height: 1.45
    margin-left: 15px

  &__add
    display: inline-block
    margin-left: 15px
    margin-top: 5px
    padding: 8px 10px 8px 0
    color: $fontColorGray
    font-size: 13px
    cursor: pointer
    transition: .25s $ease
    &:hover
      color: $colorBlueHover


  table
    border-collapse: collapse
    font-size: 13px
    width: 100%
    min-width: 960px

  thead
    color: $fontColorGray
    td
      border-top: 1px solid rgba($fontColorGray, .5)
      border-bottom: 1px solid rgba($fontColorGray, .5)
      padding: 13px 15px 11px
      color: $fontColorGray
      white-space: nowrap
      .ico
        margin-right: 5px
        // vertical-align: middle
        font-size: 12px
        overflow: hidden
        transform: translateY(1px)
        &-sh-name
          // font-size: 12px
      span
        display: inline-block
        // vertical-align: middle
    tr
      &.sh-table__top
        td
          text-align: center
          .ico-question-circle
            margin-left: 5px

          &:nth-child(1), &:nth-child(2)
            border-top: 0
          &:nth-child(1)
            border-right: 0

  tbody
    td
      // top and bottom paddings should be set with input componenet
      padding: 0


  tr
    &:first-child

    &:last-child

    td
      //last td in the row
      &:last-child
        border-right: 0

  td
    border-bottom: 1px solid rgba($fontColorGray, .5)
    border-right: 1px solid rgba($fontColorGray, .5)
    input
      -webkit-appearance: none
      display: block
      width: 100%
      height: auto
      background: transparent
      border-radius: 0px
      border: 0
      font-size: 13px
      line-height: auto
      color: $fontColor
      box-shadow: none
      padding: 12px 15px
      transition: .25s $ease
      // &::after
      //   display: inline-block
      //   content: ' '
      //   position: absolute
      //   bottom: 0
      //   left: 0
      //   right: 100%
      //   transition: .25s $ease
      //   height: 1px
      //   background: $colorBlue

      &::placeholder
        color: rgba($fontColorGray, .5)
      &:focus
        // border-color: $colorBlue
        // outline: 1px solid $colorBlue
        // &::after
        //   opacity: 1
        //   right: 0
        outline: none
      &.has-error
        outline: 1px solid $colorRed
        color: $colorRed
        box-shadow: 0px 0px 2px $colorRed
        &:focus
          outline-offset: 0px

    .ui-checkbox
      width: 24px
      margin-left: auto
      margin-right: auto
