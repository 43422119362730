/* FloatingButton.css */
.floating-button {
  position: fixed;
  bottom: 36px;
  right: 36px;
  background-color: #25D366; 
  border: none;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.icon-container {
  height: 48px; 
  width: 48px;
  background-color: none;
}