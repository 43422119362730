.pricing-options
  margin: 140px 0
  &__heading
    max-width: 570px
    margin-left: auto
    margin-right: auto
    text-align: center
  &__section-name
    font-size: 14px
    font-weight: 500
    color: white
    letter-spacing: 0.03em
    background: #9A9FB5
    text-align: center
    padding: 10px
    margin: 65px 0 23px
  &__grid
    display: flex
    margin-left: -15px
    margin-right: -15px
    &--no-heading
      margin-top: 45px
  &__col
    flex: 0 1 50%
    max-width: 50%
    min-width: 1px
    min-height: 0
    padding: 0 15px
    .pricing-option
      &:last-child
        border-bottom: 0

  &__section
    background: #FFFFFF
    box-shadow: 0 0 30px 0 rgba(0,0,0,0.05)
    border-radius: 3px
    padding: 5px 25px 10px
    width: 100%


.pricing-option
  position: relative
  display: flex
  align-items: center
  padding: 21px 0px 17px
  // possible refactor ?
  height: 90px
  border-bottom: 1px solid rgba($fontColorGray, .5)
  &__name
    flex: 0 1 auto
    display: flex
    align-items: center
    padding-right: 10px
    span
      display: inline
      // margin-right: 10px
      font-size: 13px
      color: $fontColorGray
      padding-top: 3px

  &__tooltip
    display: inline-block
    margin-left: 10px
    vertical-align: middle
    transform: translateY(-1px)
    .ico
      font-size: 17px
      color: $colorBlue
  &__price
    margin-left: auto
    flex: 0 0 auto
    max-width: 100%
    text-align: right
    &-suf
      display: inline-block
      color: $fontColorGray
      margin-right: 5px
    &-plus
      font-size: 10px
    &-main
      font-size: 13px
      color: $cabinBlue
      font-weight: 500
    &-for
      margin-top: 2px
      line-height: 1.5
      font-size: 10px
      color: $fontColorGray

+r($desktop)
  .pricing-options
    margin: 100px 0
    &__section
      padding: 0 15px

+r($tablet)
  .pricing-options
    &__grid
      display: block
      max-width: 400px
      margin-left: auto
      margin-right: auto
      box-shadow: 0 0 30px 0 rgba(0,0,0,0.05)
    &__col
      // not a flex child anymore
      // flex-basis: 100%
      max-width: 100%
      padding: 0
      // remove shadow
      position: relative
      &:first-child
        &::after
          display: inline-block
          content: ' '
          position: absolute
          left: 15px
          right: 15px
          height: 1px
          bottom: 0
          background: rgba($fontColorGray, .5)
        .pricing-options__section
          border-bottom-left-radius: 0
          border-bottom-right-radius: 0
      &:last-child
        .pricing-options__section
          border-top-left-radius: 0
          border-top-right-radius: 0

    &__section
      box-shadow: none
    &__section-name
      margin: 35px auto 20px
      max-width: 400px

+r($mobile)
  .pricing-options
    &__heading
      text-align: left

  .pricing-option__tooltip
    margin-left: 6px
